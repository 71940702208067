<template lang="pug">
.builder.pb-4
  .builder-area
    DeliveryFormHeader()
    DeliveryFormTable()
    DeliveryFormBTools(v-if="!deliveryForm.orderFormId")
    DeliveryFormFooter()
</template>
<script>
import DeliveryFormHeader from "@/components/purchase/delivery-form/builder/DeliveryFormHeader.vue";
import DeliveryFormTable from "@/components/purchase/delivery-form/builder/DeliveryFormTable.vue";
import DeliveryFormBTools from "@/components/purchase/delivery-form/builder/DeliveryFormBTools.vue";
import DeliveryFormFooter from "@/components/purchase/delivery-form/builder/DeliveryFormFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DeliveryFormHeader,
    DeliveryFormTable,
    DeliveryFormBTools,
    DeliveryFormFooter,
  },
  computed: {
    ...mapGetters(["institution", "institutionSettingsActive"]),
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
    deliveryDetails: {
      get() {
        return this.$store.getters["getDeliveryFormDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM_DETAILS", value);
      },
    },
    mandatoryInformationMissingNumber() {
      if (
        this.deliveryForm.institutionLegalForm == 0 ||
        this.deliveryForm.institutionLegalForm == 1
      ) {
        var mandatoryInformation = [
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else if (
        this.deliveryForm.institutionLegalForm == 2 ||
        this.deliveryForm.institutionLegalForm == 3
      ) {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionRcsCity",
          "institutionCapital",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      }
      let missing = 0;
      for (let i = 0; i < mandatoryInformation.length; i++) {
        const mandatory = mandatoryInformation[i];
        if (
          this.deliveryForm[mandatory] === null ||
          this.deliveryForm[mandatory] === ""
        ) {
          missing++;
        }
      }
      return missing;
    },
    institutionInformationsNotUpToDate() {
      return (
        this.institution.name != this.deliveryForm.institutionName ||
        this.institution.legalForm != this.deliveryForm.institutionLegalForm ||
        this.institution.address != this.deliveryForm.institutionAddress ||
        this.institution.addressComplement !=
          this.deliveryForm.institutionAddressComplement ||
        this.institution.zipCode != this.deliveryForm.institutionZipCode ||
        this.institution.city != this.deliveryForm.institutionCity ||
        this.institution.country != this.deliveryForm.institutionCountry ||
        this.institution.phoneNumber !=
          this.deliveryForm.institutionPhoneNumber ||
        this.institution.secondaryPhoneNumber !=
          this.deliveryForm.institutionSecondaryPhoneNumber ||
        this.institution.email != this.deliveryForm.institutionEmail ||
        this.institution.webSite != this.deliveryForm.institutionWebSite ||
        this.institution.registrationNumber !=
          this.deliveryForm.institutionRegistrationNumber ||
        this.institution.isSubjectTVA !=
          this.deliveryForm.institutionIsSubjectTVA ||
        this.institution.tvaNumber != this.deliveryForm.institutionTvaNumber ||
        this.institution.siret != this.deliveryForm.institutionSiret ||
        this.institution.rcsCity != this.deliveryForm.institutionRcsCity ||
        this.institution.capital != this.deliveryForm.institutionCapital ||
        this.institution.apeCode != this.deliveryForm.institutionApeCode ||
        this.institution.guaranteeType !=
          this.deliveryForm.institutionGuaranteeType ||
        this.institution.insuranceName !=
          this.deliveryForm.institutionInsuranceName ||
        this.institution.insuranceCoverage !=
          this.deliveryForm.institutionInsuranceCoverage ||
        this.institution.insuranceAddress !=
          this.deliveryForm.institutionInsuranceAddress ||
        this.institution.insuranceAddressComplement !=
          this.deliveryForm.institutionInsuranceAddressComplement ||
        this.institution.insuranceCity !=
          this.deliveryForm.institutionInsuranceCity ||
        this.institution.insuranceZipCode !=
          this.deliveryForm.institutionInsuranceZipCode ||
        this.institution.insuranceCountry !=
          this.deliveryForm.institutionInsuranceCountry ||
        this.institution.color != this.deliveryForm.institutionColor ||
        this.institution.secondaryColor !=
          this.deliveryForm.institutionSecondaryColor
      );
    },
  },
  methods: {
    ...mapActions(["updateDeliveryForm"]),
    redirectToInstitution() {
      this.$router.push({
        name: "edit-institution",
        params: {
          id: this.deliveryForm.institutionId,
          title: "Edit : " + this.deliveryForm.institutionName,
          tips: "Editer la société : " + this.deliveryForm.institutionName,
          routeOrigine: "institutions",
        },
      });
    },
    saveAction(bool) {
      this.updateDeliveryForm({
        deliveryForm: this.deliveryForm,
        loading: bool,
      });
    },
    updateDocumentInstitutionInformations() {
      this.deliveryForm.institutionName = this.institution.name;
      this.deliveryForm.institutionLegalForm = this.institution.legalForm;
      this.deliveryForm.institutionAddress = this.institution.address;
      this.deliveryForm.institutionAddressComplement =
        this.institution.addressComplement;
      this.deliveryForm.institutionZipCode = this.institution.zipCode;
      this.deliveryForm.institutionCity = this.institution.city;
      this.deliveryForm.institutionCountry = this.institution.country;
      this.deliveryForm.institutionPhoneNumber = this.institution.phoneNumber;
      this.deliveryForm.institutionSecondaryPhoneNumber =
        this.institution.secondaryPhoneNumber;
      this.deliveryForm.institutionEmail = this.institution.email;
      this.deliveryForm.institutionWebSite = this.institution.webSite;
      this.deliveryForm.institutionRegistrationNumber =
        this.institution.registrationNumber;
      this.deliveryForm.institutionIsSubjectTVA = this.institution.isSubjectTVA;
      this.deliveryForm.institutionTvaNumber = this.institution.tvaNumber;
      this.deliveryForm.institutionSiret = this.institution.siret;
      this.deliveryForm.institutionRcsCity = this.institution.rcsCity;
      this.deliveryForm.institutionCapital = this.institution.capital;
      this.deliveryForm.institutionApeCode = this.institution.apeCode;
      this.deliveryForm.institutionGuaranteeType =
        this.institution.guaranteeType;
      this.deliveryForm.institutionInsuranceName =
        this.institution.insuranceName;
      this.deliveryForm.institutionInsuranceCoverage =
        this.institution.insuranceCoverage;
      this.deliveryForm.institutionInsuranceAddress =
        this.institution.insuranceAddress;
      this.deliveryForm.institutionInsuranceAddressComplement =
        this.institution.insuranceAddressComplement;
      this.deliveryForm.institutionInsuranceCity =
        this.institution.insuranceCity;
      this.deliveryForm.institutionInsuranceZipCode =
        this.institution.insuranceZipCode;
      this.deliveryForm.institutionInsuranceCountry =
        this.institution.insuranceCountry;
      this.deliveryForm.institutionColor = this.institution.color;
      this.deliveryForm.institutionSecondaryColor =
        this.institution.secondaryColor;
      this.saveAction(false);
    },
  },
};
</script>

<style scoped>
#invoiceContainer {
  height: calc(100% - 23px) !important;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.builder-area {
  /* margin-top: 20px; */
  padding: 20px;
  background-color: white !important;
  border: solid 2px #f1f1f1;
}
</style>
