<template lang="pug">
    .d-flex
      .w-100(v-if="!preview")
        h4.text-primary.font-weight-bold Notes
        quill-editor.quill-editor-scroll(v-if="!preview" @blur="saveAction" class="editor editor-notes"  v-model="deliveryFormOption.comments" :options="editorOption")
      .w-100.mt-2(v-else)
        h4(v-if="deliveryForm.comments") Notes
        div(v-html="deliveryForm.comments")
  </template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions } from "vuex";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      description: null,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    deliveryFormOption: {
      get() {
        return this.$store.getters.deliveryFormOption;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM_OPTION", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateDeliveryFormOption"]),
    saveAction() {
      this.updateDeliveryFormOption({
        deliveryFormOption: this.deliveryFormOption,
        loading: false,
      });
    },
  },
};
</script>
