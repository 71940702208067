<template lang="pug">
div(style='display:contents')
  div(v-if="isLoadingDeliveryForm || isLoadingDeliveryFormPDF || isValidatingDeliveryForm" class="h-100")
    .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          |  {{ isValidatingDeliveryForm ? "Enregistrement de la sortie en cours, veuillez patienter..." : "Génération du PDF..." }}
  div(style='display:contents' v-show="!isLoadingDeliveryForm && !isLoadingDeliveryFormPDF && !isValidatingDeliveryForm")
    iframe.pdfViewver(:src="deliveryFormPDF" style="width: 100%;height: 100%; border: solid 2px #f1f1f1; padding:0px") 
    div(style="display:contents" v-if="deliveryForm.status < 3 || !deliveryForm.path || deliveryForm.path.includes('null.pdf')")
      DeliveryFormPdfContent.pdfViewver(style="width: 100%;height: 100%; border: none; padding: 0px; display:none")
        table.structure
          thead
            tr
              td
                .page-header-space
          tbody
            tr
              td
                div#invoiceContainer.pdfViewver
                  DeliveryFormPreviewHeader()
                  DeliveryFormPreviewTable()
                  DeliveryFormPreviewFooter()
                  .watermark(v-if="deliveryForm.status == 0 || deliveryForm.status == 1")
                    .text PROVISOIRE
                  .watermark(v-else-if="deliveryForm.status == 2")
                    .text A VALIDER
                  .watermark(v-else-if="deliveryForm.status == 4")
                    .text ANNULÉ
          tfoot
            tr
              td
                .page-footer-space(:style="'height:'+pageFooterHeight()+'px !important'")
                  #pageFooter
            
</template>
<script>
import DeliveryFormPdfContent from "@/components/purchase/delivery-form/DeliveryFormPdfContent";
import DeliveryFormPreviewHeader from "@/components/purchase/delivery-form/preview/DeliveryFormPreviewHeader.vue";
import DeliveryFormPreviewTable from "@/components/purchase/delivery-form/preview/DeliveryFormPreviewTable.vue";
import DeliveryFormPreviewFooter from "@/components/purchase/delivery-form/preview/DeliveryFormPreviewFooter.vue";
import { mapGetters, mapActions } from "vuex";
import { statusToUpperCaseTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import axios from "axios";

export default {
  computed: {
    ...mapGetters([
      "deliveryForm",
      "deliveryFormPDF",
      "isLoadingDeliveryForm",
      "isLoadingDeliveryFormPDF",
      "isValidatingDeliveryForm",
      "institutionSettingsActive",
    ]),
    heightFooter() {
      return "100";
    },
  },
  components: {
    DeliveryFormPdfContent,
    DeliveryFormPreviewHeader,
    DeliveryFormPreviewTable,
    DeliveryFormPreviewFooter,
  },

  methods: {
    statusToUpperCaseTranslate,
    htmlEntities(str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
    pageFooterHeight() {
      var count = 0;
      if (this.deliveryForm.footer) {
        count =
          ((this.deliveryForm.footer.match(/\<p/g) || []).length +
            (this.deliveryForm.footer.match(/\<br/g) || []).length +
            (this.deliveryForm.footer.match(/\<\/br/g) || []).length) *
          12;
      }
      if (count > 50) {
        return count;
      } else {
        return 50;
      }
    },
    decodeHtmlEntities(str) {
      var element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    },
  },
};
</script>
