<template lang="pug">
    input(@focus="emitEditFocus(true)" @blur="blurFunction(false)" class="w-100" :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress' ? 'disabled' :'']" :disabled="!edit" placeholder="" v-model="line[dataKey]") 
</template>
<script>
import { mapActions } from "vuex";
import TextCol from "@/components/purchase/delivery-form/builder/table/cols/TextCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    TextCol,
  },
  methods: {
    ...mapActions(["updateDeliveryFormDetail"]),
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      if (this.line.titre) {
        this.line.description = this.line.titre;
      }
      this.updateDeliveryFormDetail({ deliveryFormDetail: this.line });
    },
  },
};
</script>
