<template lang="pug">
div(class="invoiceHeader")
  b-row
    b-col.col-sm-6(class="invoiceHeaderLeft")
      img.logo.mb-2(v-if="deliveryForm.institutionLogo && !deliveryFormOption.hideInstitutionLogo" :src='deliveryForm.institutionLogo')
      ul 
        li.mb-2
          h2(:style="'color:'+deliveryForm.institutionColor+' !important'" v-if="!deliveryFormOption.hideInstitutionName") {{deliveryForm.institutionName}}
          div(v-if="!deliveryForm.hideInstitutionAddress")
            div {{deliveryForm.institutionAddress}}
            div(v-if="deliveryForm.institutionAddressComplement") {{deliveryForm.institutionAddressComplement}}
            div(v-if="deliveryForm.institutionZipCode || deliveryForm.institutionCity") {{deliveryForm.institutionZipCode}} {{deliveryForm.institutionCity}}
        div(v-if="!deliveryFormOption.hideCompanyCustomer")
          vs-divider.pl-0.mt-1.mb-50.text-primary(position="left" v-if="deliveryForm.deliveryType==2 ") Associé à un client / une affaire 
          li(v-if="deliveryForm.deliveryType==2")
            SelectCompany(placeholder="Sélectionner un client" :options='companiesList.filter(elem => elem.companyType.id == 5)')
          li(v-if="deliveryForm.deliveryType==2 && !deliveryFormOption.hideAffair")
            SelectAffair(placeholder="Sélectionner une affaire" :options='affairsList' :preview='true')
    b-col.col-sm-6(class="invoiceHeaderRight")
      ul(style="height:fit-content")
        li 
          h3(:style="'color:'+deliveryForm.institutionColor+' !important'") Bon de sortie n° {{ deliveryForm.documentReference ?  deliveryForm.documentReference : 'en attente' }} 
            span(style='font-weight:300')
        li(v-if="deliveryForm.nature == 2 || deliveryForm.nature == 3 || deliveryForm.nature == 4")
          div {{ natureTranslate(deliveryForm.nature)}}{{ deliveryForm.nature == 2 || deliveryForm.nature == 3 || deliveryForm.nature == 4  ? (deliveryForm.documentStep ? ` #${deliveryForm.documentStep}` : '') : '' }}
        li 
          SelectDate(keyValue="documentDate" documentDetailKey="documentDate" :preview="true" text="En date du ")
        
        div(v-if="!deliveryFormOption.hideDestockingLocation")
          vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Marchandise sortie de :
          li
            SelectDestockingLocation.mb-1(:preview="true" placeholder="Sélectionner un point de déstockage" :options='storageLocationsList')
        div(v-if="!deliveryFormOption.hideStorageLocation || !deliveryFormOption.hideCollaborator || !deliveryFormOption.hideCompanySubContractor")
          vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") {{ deliveryForm.deliveryType==1 ? 'Marchandise transférée vers :'  : 'Marchandise remise à :' }}
          li(v-if="deliveryForm.deliveryType == 0 || deliveryForm.deliveryType == 1") 
            SelectStorageLocation.mb-1(:preview="true" placeholder="Sélectionner un point de restockage" :options='storageLocationsList')
        
          li.mt-1(v-if="(deliveryForm.companySubContractorId>0 || deliveryForm.handDeliveryContactId>0 ) && deliveryForm.deliveryType==2 && !deliveryFormOption.hideCompanySubContractor")
            SelectSubContracting(:preview="true" placeholder="Sélectionner un sous-traitant" :options='companiesList.filter(elem => elem.companyType.id == 8)')
            div.mt-1(v-if="deliveryForm.handDeliveryContactId")
              SelectContact(:preview="true" placeholder="Sélectionner un contact" :options='contactsList')
          li.mt-1(v-else-if="deliveryForm.deliveryType==2 && !deliveryFormOption.hideCollaborator")
            SelectCollaboratorHandDelivery(:preview="true" placeholder="Sélectionner un collaborateur" )
  b-row
    b-col.mb-0(cols="12" class="align-items-center")
      span.text-primary.font-weight-bold.no-printting Détails des articles 
  v-style
    | table:after { background-color: {{deliveryForm.institutionColor}} !important;}     
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";

import SelectDate from "@/components/purchase/delivery-form/builder/SelectDate";
import UserInfoDeliveryForm from "@/components/purchase/delivery-form/builder/UserInfoDeliveryForm";
import SelectCompany from "@/components/purchase/delivery-form/builder/SelectCompany";
import SelectAffair from "@/components/purchase/delivery-form/builder/SelectAffair";
import SelectDestockingLocation from "@/components/purchase/delivery-form/builder/SelectDestockingLocation";
import SelectStorageLocation from "@/components/purchase/delivery-form/builder/SelectStorageLocation";
import SelectSubContracting from "@/components/purchase/delivery-form/builder/SelectSubContracting";
import SelectContact from "@/components/purchase/delivery-form/builder/SelectContact";
import SelectCollaboratorHandDelivery from "@/components/purchase/delivery-form/builder/SelectCollaboratorHandDelivery";

import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
export default {
  data() {
    return {
      deliveryTo: "collaborator",
      optionsDeliveryTo: [
        { text: "Un collaborateur", value: "collaborator" },
        { text: "Un sous-traitant", value: "subcontracting" },
      ],
      collaboratorInfo: null,
      options: [
        { label: "Heure", id: 0 },
        { label: "Jour", id: 1 },
        { label: "Semaine", id: 2 },
        { label: "Mois", id: 3 },
        { label: "Année", id: 4 },
      ],
    };
  },
  mounted() {
    this.setCollaboratorInfo();
  },
  methods: {
    ...mapActions(["getCompanyById"]),
    natureTranslate,
    natureSimplifiedTranslate,
    formatDate,
    translateDurationType(id) {
      if (id != null) {
        return this.options[id].label.toLowerCase();
      } else {
        return null;
      }
    },
    setDocumentDate(date) {
      this.deliveryForm = {
        ...this.deliveryForm,
        documentDate: date,
      };
      this.saveAction();
    },
    setDocumentTechnicalVisitDate(date) {
      this.deliveryForm.quoteCEE = {
        ...this.deliveryForm.quoteCEE,
        technicalVisitDate: date,
      };
      this.saveAction();
    },
    setSelectCounterDate(date) {
      this.deliveryForm = {
        ...this.deliveryForm,
        limitDate: date,
      };
      this.saveAction();
    },
    setWorkStartDate(date) {
      this.deliveryForm.quoteBTP = {
        ...this.deliveryForm.quoteBTP,
        workStartDate: date,
      };
      this.saveAction();
    },
    setWorkEndDate(durationNumber, durationType) {
      this.deliveryForm.quoteBTP = {
        ...this.deliveryForm.quoteBTP,
        estimatedDurationNumber: durationNumber,
        estimatedDurationType: durationType,
      };
      this.saveAction();
    },
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            let adressReturn = {
              ...adress,
              name: company.name,
              firstName: company.firstName,
              lastName: company.lastName,
            };
            resolve(adressReturn);
          }
        });
      });
    },
    async setCollaboratorInfo() {
      if (this.deliveryForm.companyId) {
        await this.getCompanyById({
          companyId: this.deliveryForm.companyId,
        }).then(async (company) => {
          this.collaboratorInfo = company.collaboratorId
            ? await this.getCollaboratorById({
                collaboratorId: company.collaboratorId,
              })
            : null;
        });
      } else {
        this.collaboratorInfo = null;
      }
    },
    async setSelectCompany(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.deliveryForm = {
          ...this.deliveryForm,
          companyId: company.id,
          companyName: company.name,
          companyFirstName: company.firstName,
          companyLastName: company.lastName,
          companyAddress: company.address,
          companyCity: company.city,
          companyZipCode: company.zipCode,
          companyCountry: company.country,
          companyMail: company.email,
          companyPhoneNumber: company.phoneNumber,
          companyTva: company.tvaNumber,
          companySiret: company.siret,
        };
        //this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.deliveryForm = {
                  ...this.deliveryForm,
                  companyId: company.id,
                  companyName: company.name,
                  companyFirstName: company.firstName,
                  companyLastName: company.lastName,
                  companyAddress: res.address,
                  companyAddressComplement: res.addressComplement,
                  companyCity: res.city,
                  companyZipCode: res.zipCode,
                  companyCountry: res.country,
                  companyMail: company.email,
                  companyPhoneNumber: company.phoneNumber,
                  companyTva: company.tvaNumber,
                  companySiret: company.siret,
                };
              });
            } else {
              this.deliveryForm = {
                ...this.deliveryForm,
                companyId: company.id,
                companyName: company.name,
                companyFirstName: company.firstName,
                companyLastName: company.lastName,
                companyAddress: null,
                companyAddressComplement: null,
                companyCity: null,
                companyZipCode: null,
                companyCountry: null,
                companyMail: company.email,
                companyPhoneNumber: company.phoneNumber,
                companyTva: company.tvaNumber,
                companySiret: company.siret,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setSelectAffair(affair) {
      this.deliveryForm = {
        ...this.deliveryForm,
        affairId: affair.id,
        affairName: affair.name,
        affairAddress: affair.address,
        affairAddressComplement: affair.addressComplement,
        affairZipCode: affair.zipCode,
        affairCity: affair.city,
        affairCountry: affair.country,
      };
      this.saveAction();
    },
    saveAction() {
      this.updateDeliveryForm({
        deliveryForm: this.deliveryForm,
        loading: false,
      });
    },
  },
  computed: {
    ...mapGetters([
      "institution",
      "companiesList",
      "affairsList",
      "natureList",
      "storageLocationsList",
      "deliveryFormOption",
    ]),
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    SelectAffair,
    SelectDate,
    UserInfoDeliveryForm,
    SelectCompany,
    SelectAffair,
    SelectDestockingLocation,
    SelectStorageLocation,
    SelectCollaboratorHandDelivery,
    SelectSubContracting,
    SelectContact,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
