<template lang="pug">
  .dropdown.search-select(v-if="canEdit && !preview")
    //- | mouseOn : {{mouseOn }} / sC : {{ searchCatalog }} / editFocus {{ editFocus }} / edit {{ edit }} / isFocusSearchCatalog {{ isFocusSearchCatalog }}
    //- quill-editor(@blur="blurFunction" :disabled="line.isDisabled"  :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled && dataKey!=='situationProgress' ? 'disabled' :'']" v-model="line['description']" :options="editorOption")
    quill-editor(ref="quillEditor" @focus="focusFunction(true)" @blur="blurFunction(false)"  :disabled="line.isDisabled"  :class="[(editFocus && mouseOn) || (searchCatalog && mouseOn) ? 'edit' : 'no-edit', editFocus? 'focus' : '', searchCatalog && isFocusSearchCatalog ? 'searchCatalog' : '', line.isDisabled && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress' ? 'disabled' :'']" v-model="line[dataKey]" :options="editorOption")
    span.feather-search-catalog(class='material-icons' :class="editFocus && mouseOn  ? 'edit' : ''" @click="activeSearchCatalog()") search
    input(class="w-100" :ref="'fakeInputSelectCatalogDelivery'+index" style="width: 0px !important; position: absolute; display:block")
    .select-results.dropdown-content(v-show="searchCatalog")
      input(class="w-100 edit" @focus="focusSearchCatalog(true)" @blur="blurSearchCatalog(false)" name="keywordsCatalog" ref="keywordsCatalog" placeholder="Saisissez vos mots-clés ou une référence pour rechercher dans le catalogue" v-model="keywordsCatalog") 
      div(v-if="keywordsCatalog.length > 1")
        div( v-for="(item, key) in productsListByTypeLine(line, catalogSortedByProductType, keywordsCatalog)")
          span.select-title {{ key }}
          div(v-if="item.length > 0")
            .d-flex.align-items-end(v-for="(product, index) in item")
              .d-flex.p-50.cursor-pointer.select-catalogue-line(style="flex:1" @click="affectValue(product)")
                div(style="width:100px") {{ product.reference }}
                div(style="flex:1")
                  span(v-html="product.description")        
                div.text-right(style="width:100px")
                  span {{ product.priceHT.toFixed(2) }} €      
          div.text-center(v-else)
            span
              | Aucun article trouvé de type {{ key.toLowerCase() }}
  div(v-else)
    span(v-html="line[dataKey]")
  </template>

<script>
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/DeliveryFormOperations";
import { productsListByTypeLine } from "@/types/api-orisis/library/DetailDeliveryFormOperations";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";

import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      isFocus: false,
      isFocusSearchCatalog: false,
      lineOrigine: {},
      editorOption: {
        placeholder: "Description...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      searchCatalog: false,
      keywordsCatalog: "",
      calculateSubTotalLineBySection,
      productsListByTypeLine,
    };
  },
  props: {
    preview: {
      default: false,
    },
    mouseOn: {
      type: Boolean,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
    },
  },
  watch: {
    editFocus(val) {
      if (
        val == false &&
        (this.editFocus == true || this.searchCatalog == true)
      ) {
        this.$refs["fakeInputSelectCatalogDelivery" + this.index]?.focus({
          preventScroll: true,
        });
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      } else if (val == false) {
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      }
    },
  },
  created() {
    this.lineOrigine = JSON.parse(JSON.stringify(this.line[this.dataKey]));
    this.searchCatalog = false;
  },
  mounted() {
    this.searchCatalog = false;
  },
  computed: {
    ...mapGetters(["catalogSortedByProductType", "deliveryForm"]),
    documentDetails: {
      get() {
        return this.$store.getters["getDeliveryFormDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM_DETAILS", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateDeliveryFormDetail"]),
    activeSearchCatalog() {
      this.searchCatalog = true;
      let _this = this;
      this.$nextTick(() => {
        _this.$refs.keywordsCatalog.focus({ preventScroll: true });
      });
    },
    focusSearchCatalog(res) {
      this.isFocusSearchCatalog = res;
    },
    blurSearchCatalog(res) {
      if (!this.mouseOn) {
        this.$emit("emitEditFocus", false);
        this.searchCatalog = false;
      }
    },
    focusFunction(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.$emit("emitEditFocus", res);
      // if(!this.mouseOn) this.$emit("emitEditFocus", res);
      if (
        JSON.parse(JSON.stringify(this.line[this.dataKey])) !== this.lineOrigine
      ) {
        this.updateDeliveryFormDetail({ deliveryFormDetail: this.line });
      }
    },
    async affectValue(val) {
      if (this.documentDetails.includes((elem) => elem.productId == val.id)) {
        this.$bvToast.toast(
          "Ce produit est déjà présent dans le bon de réception",
          {
            title: "Erreur",
            variant: "danger",
            solid: true,
          }
        );
      } else {
        this.line["description"] = val.description
          ? val.description
          : val.label;
        this.line["productId"] = val.id;
        this.line["reference"] = val.reference ? val.reference : null;
        this.line["unitId"] = val.unit ? val.unit.id : this.line["unitId"];
        this.line["unitPriceHt"] = val.priceHT
          ? val.priceHT
          : this.line["unitPriceHt"];
        this.line["total"] = val.priceHT
          ? Math.round(this.line["outPutQuantity"] * val.priceHT * 100) / 100
          : this.line["total"];
        this.line["referencielTvaId"] = val.referencielTvaId
          ? val.referencielTvaId
          : this.line["referencielTvaId"];
        this.line["quantity"] = val.quantity
          ? val.quantity
          : this.line["quantity"];

        switch (val.productType) {
          case 0:
            this.line["type"] = 7;
            break;
          case 2:
            this.line["type"] = 9;
            break;
          case 4:
            this.line["type"] = 6;
            break;
          case 5:
            this.line["type"] = 8;
            break;
        }
        await this.updateDeliveryFormDetail({ deliveryFormDetail: this.line });
        await this.calculateSubTotalLineBySection(this.line);
        this.keywordsCatalog = "";
        this.searchCatalog = false;
      }
    },
  },

  components: {
    "v-select": vSelect,
    quillEditor,
  },
};
</script>
