<template lang="pug">
div(v-if="!deliveryFormHeader[dataKey].editable")
  div.content-span-document-detail
    span(v-model="line[dataKey]") 
    span {{ deliveryFormHeader[dataKey].prefix }} 
    span {{ formatNumberToString(line[dataKey]) }}
    span {{ deliveryFormHeader[dataKey].unit }}
input.w-100(v-else @focus="emitEditFocus(true)" @blur="blurFunction(false)" type="number" min='0'  :class="edit && canEdit ? 'edit' : 'no-edit'" v-model="line[dataKey]") 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/DeliveryFormOperations";
import {
  formatNumber,
  formatNumberToString,
} from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {
      calculateSubTotalLineBySection,
      error: true,
    };
  },

  methods: {
    ...mapActions(["updateDeliveryFormDetail"]),
    formatNumber,
    formatNumberToString,
    change(res, calculateSubTotal = false) {
      this.line[this.dataKey] = formatNumber(this.line[this.dataKey]);
      this.line["total"] = formatNumber(
        this.line["unitPriceHt"] * this.line["outPutQuantity"]
      );
      if (calculateSubTotal) this.calculateSubTotalLineBySection(this.line);
    },
    async blurFunction(res) {
      this.$emit("emitEditFocus", res);
      // await this.test()
      await this.change(null, true);
      await this.updateDeliveryFormDetail({ deliveryFormDetail: this.line });
    },
    emitEditFocus(res) {
      this.edit = res;
      this.$emit("emitEditFocus", res);
    },
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    mouseOn: {
      type: Boolean,
    },
  },
};
</script>
