<template lang="pug">
#invoiceContainer(v-if="this.$route.fullPath.includes(deliveryForm.id)")
  .content-loader-center.m-0(v-if='isLoadingDeliveryForm && isLoadingDeliveryFormDetailDuplication')
    .text-center.flex-center
      .loading-bg-inner(role='status')
        .loader
          .outer
          .middle
          .inner
      br
      | Chargement du bon de sortie...
  div(v-else style="height: calc(100% - 50px) !important")
    DeliveryFormBuilderHeader(:key="id" @changePreviewKey="changePreviewKey")
    DeliveryFormBuilder(v-if="deliveryFormTabActive == 'delivery-form-edit'")
    .content-tab-fullpage(v-else-if="deliveryFormTabActive == 'delivery-form-pdf'" :key="previewKey")
      DeliveryFormPdf
    .content-tab-fullpage(v-else-if="deliveryFormTabActive == 'internal-note'")
      InternalNote
    .content-tab-fullpage(v-else-if="deliveryFormTabActive == 'attachments'")
      ged-viewer-fullpage(:parentFolder="deliveryForm.folderId" :fromModule="'deliveryForm'")
</template>

<script>
import DeliveryFormBuilderHeader from "@/components/purchase/delivery-form/DeliveryFormBuilderHeader.vue";
import DeliveryFormBuilder from "@/components/purchase/delivery-form/DeliveryFormBuilder.vue";
import DeliveryFormPdf from "@/components/purchase/delivery-form/DeliveryFormPdf.vue";
import DeliveryFormPreview from "@/components/purchase/delivery-form/DeliveryFormPreview.vue";
import { mapGetters, mapActions } from "vuex";
import { DeliveryFormDefaultProperties } from "@/types/api-orisis/models/DeliveryFormModels";
import InternalNote from "@/components/purchase/delivery-form/internal-note/InternalNote.vue";
import GedViewerFullpage from "@/components/ged/GedViewerFullpage";

export default {
  name: "edit-delivery-form",
  ref: "edit-delivery-form",

  data() {
    return {
      DeliveryFormDefaultProperties,
      previewKey: 0,
    };
  },
  props: {
    id: {
      default: null,
    },
    mode: {
      default: "delivery-form-edit",
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async created() {
    this.getProducts({});

    if (this.deliveryForm.status == 3) {
      this.changeDeliveryFormTabActive("delivery-form-pdf");
    } else {
      this.changeDeliveryFormTabActive(this.mode);
    }

    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({}).then((res) => {
        this.deliveryFormHeader.unitId.choice = res;
      });
    } else {
      this.deliveryFormHeader.unitId.choice = this.unitsList;
    }

    this.deliveryFormHeader.referencielTvaId.choice =
      this.institutionSettingsActive.referencielTvas.map((elem) => {
        return {
          id: elem.referencielTva.id,
          label: elem.referencielTva.label,
          value: elem.referencielTva.value,
        };
      });

    if (this.deliveryForm.destockingLocationId)
      this.getStockOfProductByStorageLocationId({
        storageLocationId: this.deliveryForm.destockingLocationId,
      });
  },
  computed: {
    ...mapGetters([
      "isLoadingDeliveryForm",
      "deliveryFormTabActive",
      "unitsList",
      "isLoadingDeliveryFormDetailDuplication",
      "institutionSettingsActive",
    ]),
    deliveryFormHeader: {
      get() {
        return this.$store.getters.getDeliveryFormHeader;
      },
      set(val) {
        this.$store.commit("setupHeaderDeliveryForm", {
          ...this.deliveryFormHeader,
          val,
        });
      },
    },
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getStockOfProductByStorageLocationId",
      "getUnits",
      "changeDeliveryFormTabActive",
      "getProducts",
    ]),
    changePreviewKey() {
      this.previewKey++;
    },
  },
  components: {
    DeliveryFormBuilderHeader,
    DeliveryFormBuilder,
    DeliveryFormPdf,
    DeliveryFormPreview,
    InternalNote,
    GedViewerFullpage,
  },
};
</script>

<style scoped>
#invoiceContainer {
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.content-tab-fullpage {
  padding-top: 20px !important;
  background-color: white !important;
  height: 100%;
}
</style>
