<template lang="pug">
  //- div {{deliveryFormHeader[dataKey].inputType}} 
  div(v-if="deliveryFormHeader[dataKey].inputType == 1") 
    TextCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :editFocus="editFocus")
  //- div(v-else-if="deliveryFormHeader[dataKey].inputType == 2") 
  //-   NumberCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :editFocus="editFocus")
  div(v-else-if="deliveryFormHeader[dataKey].inputType == 21")
    QuillEditorCol(@emitEditFocus="emitEditFocus" :line="line" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :canEdit="true" :edit="edit" :editFocus="editFocus")
</template>
<script>
import TextCol from "@/components/purchase/delivery-form/builder/table/cols/TextCol.vue";
import NumberCol from "@/components/purchase/delivery-form/builder/table/cols/NumberCol.vue";
import QuillEditorCol from "@/components/purchase/delivery-form/builder/table/cols/QuillEditorCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TextCol,
    NumberCol,
    QuillEditorCol,
  },
};
</script>
