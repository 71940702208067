<template lang="pug">
    .row
        .col-md-6.toolbar.w-100.d-flex
            b-button.mr-1.button-tools(@click="newLine({type:7, unit : 1, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine7")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Fourniture
            
            b-button.mr-1.button-tools(@click="newLine({type: 8, unit: 2, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine8")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Main d'oeuvre

            b-button.mr-1.button-tools(@click="newLine({type : 9, unit: 1, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine9")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Matériel
            b-button.mr-1.button-tools(@click="newLine({type : 10, unit: 1, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine10")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Autre
        .col-md-6.toolbar.w-100.d-flex.align-right.justify-content-end
            b-dropdown.mr-1(v-if="includeSection" variant="outline-primary" text="Section ...") 
                b-dropdown-item(@click="newLine({type: 1, indexArray: lastDocumentLine, review: false})") Section
                b-dropdown-item(@click="newLine({type: 2, indexArray: lastDocumentLine, review: false})") Sous-Section

            b-button.mr-1.button-tools(v-else @click="newLine({type:1, indexArray: lastDocumentLine, review: false})" variant="outline-dark") 
                div(v-if="isLoadingLine1")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Section

            //- b-button.mr-1.button-tools(@click="addSubTotalLine({indexArray: lastDocumentLine})" variant="outline-dark") 
            //-     div(v-if="isLoadingLine5")
            //-         b-spinner.mr-1(small)
            //-         | Chargement...
            //-     div(v-else)
            //-         span Sous-total

            b-button.mr-1.button-tools(@click="newLine({type:6, indexArray: lastDocumentLine, review: false})" variant="outline-dark") 
                div(v-if="isLoadingLine6")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Texte

            b-dropdown(variant="outline-primary" text="Saut ...") 
                b-dropdown-item(@click="newLine({type: 3, indexArray: lastDocumentLine, review: false})") Saut de ligne
                b-dropdown-item(@click="newLine({type: 4, indexArray: lastDocumentLine, review: false})") Saut de page
</template>
<script>
import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import { calculateSubTotalLine } from "@/types/api-orisis/library/DeliveryFormOperations";

export default {
  data() {
    return {
      isLoadingLine1: false,
      isLoadingLine5: false,
      isLoadingLine6: false,
      isLoadingLine7: false,
      isLoadingLine8: false,
      isLoadingLine9: false,
      isLoadingLine10: false,
    };
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["deliveryForm", "institutionSettingsActive"]),
    lastDocumentLine() {
      return this.$store.getters.getDeliveryFormDetails.length - 1;
    },
    includeSection() {
      return (
        this.$store.getters.getDeliveryFormDetails.filter((el) => el.type == 1)
          .length > 0
      );
    },
  },
  methods: {
    calculateSubTotalLine,
    ...mapActions(["addLineDeliveryForm"]),
    changeLoading(line, bool) {
      if (line.type == 1) this.isLoadingLine1 = bool;
      if (line.type == 5) this.isLoadingLine5 = bool;
      if (line.type == 6) this.isLoadingLine6 = bool;
      if (line.type == 7) this.isLoadingLine7 = bool;
      if (line.type == 8) this.isLoadingLine8 = bool;
      if (line.type == 9) this.isLoadingLine9 = bool;
      if (line.type == 10) this.isLoadingLine10 = bool;
    },
    async addSubTotalLine(line) {
      this.isLoadingLine5 = true;
      await this.calculateSubTotalLine(
        {
          ...line,
          type: 5,
          review: false,
          deliveryFormId: this.deliveryForm.id,
          referencielTvaId: 5,
          outPutQuantity: 1,
          description: "Sous-total",
        },
        true
      );
      this.isLoadingLine5 = false;
    },
    newLine(line) {
      this.changeLoading(line, true);
      if (this.$store.getters.deliveryForm.destockingLocationId) {
        this.addLineDeliveryForm({
          deliveryFormDetail: {
            ...line,
            deliveryFormId: this.deliveryForm.id,
            referencielTvaId:
              this.institutionSettingsActive.referencielTvas.find(
                (elem) => elem.isDefault == true
              ).referencielTva.id,
          },
          review: line.review,
          indexArray: line.indexArray,
        })
          .then((res) => {
            this.changeLoading(line, false);
          })
          .catch((err) => {
            this.changeLoading(line, false);
          });
      } else {
        this.changeLoading(line, false);
        this.$bvModal.msgBoxConfirm(
          "La sélection d'un point de destockage est obligatoire pour continuer, veuillez l'indiquer pour que votre liste de produit se charge en fonction de celui-ci",
          {
            title: "Veuillez sélectionner un lieu de destockage",
            size: "sm",
            okVariant: "primary",
            okTitle: "D'accord",
            cancelTitle: "Fermer",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            okOnly: true,
            centered: true,
          }
        );
      }
    },
  },
  mounted() {
    this.isLoadingLine1 = false;
    this.isLoadingLine5 = false;
    this.isLoadingLine6 = false;
    this.isLoadingLine7 = false;
    this.isLoadingLine8 = false;
    this.isLoadingLine9 = false;
    this.isLoadingLine10 = false;
  },
};
</script>
