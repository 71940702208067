<template lang="pug">
div 
    ejs-dialog(id="dialog" ref="templateDialog" :header="headerTemplate" :content="contentTemplate" :footerTemplate="footerTemplate" width="500" :showCloseIcon="true" :close="toggleActive" v-if="isActiveModalEditRefProduct")
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

var headerTemplate = Vue.component("HeaderTemplate", {
  template:
    '<p class="m-0">Le produit <span style="font-weight: 700">{{productToCreate.reference}}</span> n\'existe pas.</p>',
  computed: {
    ...mapGetters(["productToCreate"]),
  },

});

let contentTemplate = Vue.component("ContentTemplate", {
  template: `<div>
        <p class="mt-1">Attention, la référence <span style="font-weight: 700">{{productToCreate.reference}}</span> n'existe pas dans votre catalogue produit. Souhaitez vous le créer ?</p>
          <b-form-checkbox class="mt-1" v-model="productToCreate.unAvailableForSale" > Indisponible à la vente </b-form-checkbox>
        </div>
    </div>`,
  computed: {
    ...mapGetters([
      "productToCreate",
    ]),
  },
});

let footerTemplate = Vue.component("FooterTemplate", {
  template: `<ejs-button class="e-primary" v-on:click.native="createProductToDeliveryForm">Créer</ejs-button>`,
  methods: {
    ...mapActions([
      "createProductToDeliveryForm",
    ]),
  },
});

export default {
  data() {
    return {
      headerTemplate: function () {
        return { template: headerTemplate };
      },
      contentTemplate: function () {
        return { template: contentTemplate };
      },
      footerTemplate: function () {
        return { template: footerTemplate };
      },
    };
  },
  computed: {
    ...mapGetters(["isActiveModalEditRefProduct", "productToCreate"]),
  },
  methods: {
    toggleActive(e) {
      e.event.preventDefault();
      this.$store.commit("SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT", false);
    },
  },
};
</script>