<template lang="pug">
.h-100(v-if='isLoadingDeliveryFormPDF')
  .text-center.flex-center.h-100
    .loading-bg-inner
      .loader
        .outer
        .middle
        .inner
    .mt-5
      br
      br
      br
      | Génération du PDF
iframe.pdfViewver(:src="deliveryFormPDF" v-else style="width: 100%;height: 100%; border: none;") 
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["deliveryFormPDF", "isLoadingDeliveryFormPDF"]),
  },
};
</script>
