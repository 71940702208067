<template lang="pug">
div 
  b-row
    b-col(cols="12").col-lg-5
      ul
        li 
          h3.text-primary Bon de sortie n° {{ deliveryForm.documentReference ? deliveryForm.documentReference : 'en attente' }} 
            span(style='font-weight:300')
        li.d-flex.align-items-center
          Status(:statusId="deliveryForm.status" nature="deliveryForm" :static="true")
          SelectDate(keyValue="documentDate" @setValue="setDocumentDate" documentDetailKey="documentDate" text="En date du ")
        div(v-if="!deliveryFormOption.hideUser")
          vs-divider.pl-0.mt-2.mb-50.text-primary(position="left") Traité par
          li.mt-1
            UserInfoDeliveryForm(placeholder="Sélectionner un utilisateur" )
        div
          vs-divider.pl-0.mt-1.mb-50.text-primary(position="left" v-if="deliveryForm.deliveryType==2 ") Associé à un client / une affaire 
          li(v-if="deliveryForm.deliveryType==2")
            SelectCompany(@setValue="setSelectCustomer" @setSelectCompanyAddress="setSelectCompanyAddress" placeholder="Sélectionner un client" :options='companiesList.filter(elem => elem.companyType.id == 5)')
          li(v-if="deliveryForm.deliveryType==2")
            SelectAffair(@setValue="setSelectAffair" placeholder="Sélectionner une affaire" :options='affairsList')
    b-col(cols="12").offset-lg-1.col-lg-6
      div
        vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Point de déstockage
        div 
          SelectDestockingLocation.mb-1(@setValue="setSelectDestockingLocation" placeholder="Sélectionner un point de déstockage" :options='storageLocationsList.filter(el=>el.id!==deliveryForm.storageLocationId)')
      div
        vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") {{ deliveryForm.deliveryType==1 ? 'Nouveau point de stockage'  : 'Marchandise remise à ' }}
        div(v-if="deliveryForm.deliveryType==0")
          SelectCollaboratorHandDelivery(placeholder="Sélectionner un collaborateur" )
        div(v-if="deliveryForm.deliveryType==1") 
          SelectStorageLocation.mb-1(@setValue="setSelectStorageLocation" placeholder="Sélectionner un point de restockage" :options='storageLocationsList.filter(el=>el.id!==deliveryForm.destockingLocationId)')
        div(v-if="deliveryForm.deliveryType==2")
          div
            b-form-group( v-slot='{ ariaDescribedby }')
              b-form-radio-group(class="demo-inline-spacing" @input="changeDeliveryTo" v-model='deliveryTo' :options='optionsDeliveryTo' :aria-describedby='ariaDescribedby' name='plain-inline' plain='')
          div.mt-1(v-if="deliveryForm.companySubContractorId>0 || deliveryForm.handDeliveryContactId>0 || deliveryTo=='subcontracting'")
            SelectSubContracting(@setValue="setSelectSubContracting" placeholder="Sélectionner un sous-traitant" :options='companiesList.filter(elem => elem.companyType.id == 8)')
            div.mt-1
              SelectContact(placeholder="Sélectionner un contact" :options='contactsList')
          div.mt-1(v-else)
            SelectCollaboratorHandDelivery(placeholder="Sélectionner un collaborateur" )
  b-row
    b-col.mb-0(cols="12" class="align-items-center")
      span.text-primary.font-weight-bold.no-printting Détails des articles 
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";

import { BFormRadio } from "bootstrap-vue";

import Status from "@/components/global/status/Status";
var dayjs = require("dayjs");
import SelectSubContracting from "@/components/purchase/delivery-form/builder/SelectSubContracting";

import SelectContact from "@/components/purchase/delivery-form/builder/SelectContact";
import SelectCompany from "@/components/purchase/delivery-form/builder/SelectCompany";
import SelectAffair from "@/components/purchase/delivery-form/builder/SelectAffair";
import SelectDestockingLocation from "@/components/purchase/delivery-form/builder/SelectDestockingLocation";
import SelectStorageLocation from "@/components/purchase/delivery-form/builder/SelectStorageLocation";

import SelectDate from "@/components/purchase/delivery-form/builder/SelectDate";
import SelectDeliveryDate from "@/components/purchase/delivery-form/builder/SelectDeliveryDate";

import DeliveryFormDescription from "@/components/purchase/delivery-form/builder/DeliveryFormDescription";

import UserInfoDeliveryForm from "@/components/purchase/delivery-form/builder/UserInfoDeliveryForm";
import SelectCollaboratorHandDelivery from "@/components/purchase/delivery-form/builder/SelectCollaboratorHandDelivery";
import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      collaboratorInfo: null,
      deliveryTo: "collaborator",
      optionsDeliveryTo: [
        { text: "Un collaborateur", value: "collaborator" },
        { text: "Un sous-traitant", value: "subcontracting" },
      ],
    };
  },
  mounted() {
    this.setCollaboratorInfo();
    if (
      this.deliveryForm.companySubContractorId > 0 ||
      this.deliveryForm.handDeliveryContactId > 0
    ) {
      this.deliveryTo = "subcontracting";
    }
  },
  methods: {
    ...mapActions([
      "updateDeliveryForm",
      "getCompanyById",
      "getCollaboratorById",
      "getStockOfProductByStorageLocationId",
    ]),
    natureTranslate,
    natureSimplifiedTranslate,
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    changeDeliveryTo(val) {
      if (val == "collaborator") {
        this.deliveryTo = "collaborator";
        this.deliveryForm = {
          ...this.deliveryForm,
          companySubContractorId: null,
          companySubContractorName: null,
          companySubContractorAddress: null,
          companySubContractorAddressComplement: null,
          companySubContractorCity: null,
          companySubContractorZipCode: null,
          companySubContractorCountry: null,
          handDeliveryContactCivility: 0,
          handDeliveryContactLastName: "",
          handDeliveryContactFirstName: "",
          handDeliveryContactPhoneNumber: "",
          handDeliveryContactEmail: "",
          handDeliveryContactId: null,
        };
        this.saveAction();
      } else {
        this.deliveryTo = "subcontracting";
        this.deliveryForm = {
          ...this.deliveryForm,
          collaboratorLastName: "",
          collaboratorFirstName: "",
          collaboratorPhoneNumber: "",
          collaboratorMail: "",
          collaboratorId: null,
        };
        this.saveAction();
      }
    },
    setSelectCompanyAddress(company) {
      this.document = {
        ...this.document,
        companyId: company.id,
        companyCode: company.code,
        companyName: company.name,
        companyAddress: company.address,
        companyAddressId: company.addressId,
        companyAddressComplement: company.addressComplement,
        companyCity: company.city,
        companyZipCode: company.zipCode,
        companyCountry: company.country,
        companySiret: company.siret,
        companyTva: company.tvaNumber,
        companyMail: company.email,
        companyPhoneNumber: company.phoneNumber,
        companyAddressLabel: company.label,
      };
      this.saveAction();
    },
    setDocumentDate(date) {
      this.deliveryForm = {
        ...this.deliveryForm,
        documentDate: date,
      };
      this.saveAction();
    },
    setDocumentDeliveryDate(date) {
      this.deliveryForm = {
        ...this.deliveryForm,
        deliveryDate: date,
      };
      this.saveAction();
    },
    setDeliveryDate(date) {
      this.deliveryForm = {
        ...this.deliveryForm,
        deliveryDate: date,
      };
      this.saveAction();
    },
    setDocumentTechnicalVisitDate(date) {
      this.deliveryForm.quoteCEE = {
        ...this.deliveryForm.quoteCEE,
        technicalVisitDate: date,
      };
      this.saveAction();
    },
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            resolve(adress);
          }
        });
      });
    },
    async setCollaboratorInfo() {
      if (this.deliveryForm.companyId) {
        await this.getCompanyById({
          companyId: this.deliveryForm.companyId,
        }).then(async (company) => {
          this.collaboratorInfo = company.collaboratorId
            ? await this.getCollaboratorById({
                collaboratorId: company.collaboratorId,
              })
            : null;
        });
      } else {
        this.collaboratorInfo = null;
      }
    },
    async setSelectSubContracting(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.deliveryForm = {
          ...this.deliveryForm,
          companySubContractorId: company.id,
          companySubContractorName: company.name,
          companySubContractorAddress: company.address,
          companySubContractorAddressComplement: company.addressComplement,
          companySubContractorCity: company.city,
          companySubContractorZipCode: company.zipCode,
          companySubContractorCountry: company.country,
          companySubContractorCode: company.code,
          companySubContractorPhoneNumber: company.phoneNumber,
          companySubContractorMail: company.email,
          companySubContractorSiret: company.siret,
          companySubContractorTva: company.tvaNumber,
          companySubContractorAddressLabel: company.addressLabel,
        };
        this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.deliveryForm = {
                  ...this.deliveryForm,
                  companySubContractorId: company.id,
                  companySubContractorName: company.name,
                  companySubContractorAddress: res.address,
                  companySubContractorAddressComplement: res.addressComplement,
                  companySubContractorCity: res.city,
                  companySubContractorZipCode: res.zipCode,
                  companySubContractorCountry: res.country,
                  companySubContractorCode: company.code,
                  companySubContractorPhoneNumber: company.phoneNumber,
                  companySubContractorMail: company.email,
                  companySubContractorSiret: res.siret,
                  companySubContractorTva: company.tvaNumber,
                  companySubContractorAddressLabel: res.label,
                };
              });
            } else {
              this.deliveryForm = {
                ...this.deliveryForm,
                companySubContractorId: company.id,
                companySubContractorName: company.name,
                companySubContractorAddress: null,
                companySubContractorAddressComplement: null,
                companySubContractorCity: null,
                companySubContractorZipCode: null,
                companySubContractorCountry: null,
                companySubContractorCode: company.code,
                companySubContractorPhoneNumber: company.phoneNumber,
                companySubContractorMail: company.email,
                companySubContractorSiret: null,
                companySubContractorTva: company.tvaNumber,
                companySubContractorAddressLabel: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    async setSelectCustomer(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.deliveryForm = {
          ...this.deliveryForm,
          companyCustomerId: company.id,
          companyCustomerName: company.name,
          companyCustomerAddress: company.address,
          companyCustomerAddressComplement: company.addressComplement,
          companyCustomerCity: company.city,
          companyCustomerZipCode: company.zipCode,
          companyCustomerCountry: company.country,
          companyCustomerCode: company.code,
          companyCustomerPhoneNumber: company.phoneNumber,
          companyCustomerMail: company.email,
          companyCustomerSiret: company.siret,
          companyCustomerTva: company.tvaNumber,
          companyCustomerAddressLabel: company.label,
        };
        this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.deliveryForm = {
                  ...this.deliveryForm,
                  companyCustomerId: company.id,
                  companyCustomerName: company.name,
                  companyCustomerAddress: res.address,
                  companyCustomerAddressComplement: res.addressComplement,
                  companyCustomerCity: res.city,
                  companyCustomerZipCode: res.zipCode,
                  companyCustomerCountry: res.country,
                  companyCustomerCode: company.code,
                  companyCustomerPhoneNumber: company.phoneNumber,
                  companyCustomerMail: company.email,
                  companyCustomerSiret: res.siret,
                  companyCustomerTva: company.tvaNumber,
                  companyCustomerAddressLabel: res.label,
                };
              });
            } else {
              this.deliveryForm = {
                ...this.deliveryForm,
                companyCustomerId: company.id,
                companyCustomerName: company.name,
                companyCustomerAddress: null,
                companyCustomerAddressComplement: null,
                companyCustomerCity: null,
                companyCustomerZipCode: null,
                companyCustomerCountry: null,
                companyCustomerCode: company.code,
                companyCustomerPhoneNumber: company.phoneNumber,
                companyCustomerMail: company.email,
                companyCustomerSiret: null,
                companyCustomerTva: company.tvaNumber,
                companyCustomerAddressLabel: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setSelectStorageLocation(storageLocation) {
      this.deliveryForm = {
        ...this.deliveryForm,
        storageLocationId: storageLocation.id,
        storageLocationLabel: storageLocation.label,
        storageLocationAddress: storageLocation.address,
        storageLocationAddressComplement: storageLocation.addressComplement,
        storageLocationZipCode: storageLocation.zipCode,
        storageLocationCity: storageLocation.city,
        storageLocationEmail: storageLocation.email,
        storageLocationPhoneNumber: storageLocation.phoneNumber,
        storageLocationType: storageLocation.storageLocationType,
      };
      if (storageLocation.id) {
        this.saveAction();
      }
    },
    setSelectDestockingLocation(storageLocation) {
      this.getStockOfProductByStorageLocationId({
        storageLocationId: storageLocation.id,
      });
      this.deliveryForm = {
        ...this.deliveryForm,
        destockingLocationId: storageLocation.id,
        destockingLocationLabel: storageLocation.label,
        destockingLocationAddress: storageLocation.address,
        destockingLocationAddressComplement: storageLocation.addressComplement,
        destockingLocationZipCode: storageLocation.zipCode,
        destockingLocationCity: storageLocation.city,
        destockingLocationEmail: storageLocation.email,
        destockingLocationPhoneNumber: storageLocation.phoneNumber,
        destockingLocationType: storageLocation.storageLocationType,
      };
      if (storageLocation.id) {
        this.saveAction();
      }
    },

    setSelectAffair(affair) {
      this.deliveryForm = {
        ...this.deliveryForm,
        affairId: affair.id,
        affairName: affair.name,
        affairAddress: affair.address,
        affairAddressComplement: affair.addressComplement,
        affairZipCode: affair.zipCode,
        affairCity: affair.city,
        affairCountry: affair.country,
        affairCode: affair.code,
      };
      this.saveAction();
    },
    saveAction() {
      this.updateDeliveryForm({
        deliveryForm: this.deliveryForm,
      });
    },
  },
  computed: {
    ...mapGetters([
      "companiesList",
      "affairsList",
      "storageLocationsList",
      "natureList",
      "contactsList",
      "deliveryFormOption",
    ]),
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    BFormRadio,
    SelectCompany,
    SelectSubContracting,
    SelectContact,
    SelectDeliveryDate,
    SelectAffair,
    SelectStorageLocation,
    SelectDestockingLocation,
    SelectCollaboratorHandDelivery,
    SelectDate,
    DeliveryFormDescription,
    UserInfoDeliveryForm,
    Status,
  },
};
</script>
<style>
.demo-inline-spacing > * {
  margin-top: 0;
}
</style>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
