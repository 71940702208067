<template lang="pug">
.row.align-items-end
  div.col-md-9(:class="deliveryFormOption.hideTotal ? 'col-md-9' : 'col-md-12'")
    BottomNotes
  div.col-md-3(v-show="deliveryFormOption.hideTotal")
    TotalDocument(@updateTvaDetails='updateTvaDetails' ref="TotalDeliveryForm")
</template>
<script>
import TotalDocument from "@/components/purchase/delivery-form/builder/TotalDeliveryForm";
import TotalTva from "@/components/purchase/delivery-form/builder/TotalTva";
import BottomNotes from "@/components/purchase/delivery-form/builder/BottomNotes.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tvaDetails: [],
    };
  },
  computed: {
    ...mapGetters(["deliveryForm", "deliveryFormOption"]),
    document: {
      get() {
        return this.deliveryForm;
      },
      set(value) {
        this.$store.commit("setDeliveryForm", value);
      },
    },
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    },
  },
  components: {
    TotalDocument,
    TotalTva,
    BottomNotes,
  },
};
</script>
