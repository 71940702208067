<template lang="pug">
    div(class="invoiceFooter")
        .row
          .col-sm-6.mt-1.invoiceFooterLeft
            TotalTva.mt-2
          div.col-sm-6.mt-2.invoiceFooterRight
            TotalDeliveryForm(@updateTvaDetails='updateTvaDetails' v-if="deliveryForm.isShowTotalPrice" preview="true" ref="TotalDeliveryForm")
            div.print-signature.mt-2.border.rounded.content-total(style="page-break-inside: avoid;" v-show="deliveryForm.nature==0")
                | Signature du client précédée de la mention "Lu et approuvé, bon pour accord" :
        .print-footer.mb-1(ref="printFooter")
            span(v-html="deliveryForm.footer")
    </template>
<script>
import TotalDeliveryForm from "@/components/purchase/delivery-form/builder/TotalDeliveryForm";
import TotalTva from "@/components/purchase/delivery-form/builder/TotalTva";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tvaDetails: [],
      printFooterHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["deliveryForm"]),
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    },
  },
  components: {
    TotalDeliveryForm,
    TotalTva,
  },
};
</script>
