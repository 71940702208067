<template lang="pug">
.quote-card(v-if="!preview")
  div(v-if="!deliveryForm.collaboratorId")
    b-form-group(label-for='storage-location')
      validation-provider(#default='{ errors }' name='storage-location')
        v-select#company.mb-1(ref="selectCollaborator" @input="setCollaborator" :loading="isLoadingCollaboratorsList" :state="errors.length > 0 ? false : null" v-model='deliveryForm.collaboratorId' :options="collaboratorsList" :get-option-label="(collab) => collab.lastName + ' ' + collab.firstName" :reduce="(elem) => elem.id" :placeholder="placeholder")
          template(v-slot:no-options)
            template  Aucun résultat trouvé
          li.border-bottom.p-1.py-50(slot='list-header')
            b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newCollaborator()' variant='primary' size='sm')
              | + Nouveau collaborator
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
        small.text-danger {{ errors[0] }}
  div(v-if="editMode")
    div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
      span.text-primary.font-weight-bold Collaborateur :
      span.cursor-pointer.text-primary(v-if="editMode" @click='saveCollaborator') Enregistrer
    div.mb-1
      b-form-group(label-for='collabId' style='flex:1')
        validation-provider(#default='{ errors }' name='collabId' rules="required")
          v-select#collabId(:loading='isLoadingCollaboratorsList' :clearable='false' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='deliveryForm.collaboratorId' :options="collaboratorsList" :get-option-label="(collab) => collab.lastName + ' ' + collab.firstName" :reduce="(elem) => elem.id" @input="setCollaborator")
                template(v-slot:no-options='')
                    template  Aucun résultat trouvé
    //div.mb-1.d-flex
      div.w-50
        b-form-group(label-for='collaboratorFirstName' style='flex:1')
          validation-provider(#default='{ errors }' name='collaboratorFirstName')
          b-form-input(placeholder="Prénom" v-model="deliveryForm.collaboratorFirstName")
      div.w-50
        b-form-group(label-for='collaboratorLastName' style='flex:1')
          validation-provider(#default='{ errors }' name='collaboratorLastName')
          b-form-input(placeholder="Nom" v-model="deliveryForm.collaboratorLastName")
    div.mb-1
      b-form-group(label-for='collabMail' style='flex:1')
        validation-provider(#default='{ errors }' name='collabMail')
          b-form-input(placeholder="Email" v-model="deliveryForm.collaboratorMail")
    div.mb-1.d-flex
      div.w-50
        b-form-group(label-for='collaboratorPhoneNumber' style='flex:1')
          validation-provider(#default='{ errors }' name='collaboratorPhoneNumber')
            b-form-input(placeholder="Téléphone principal" v-model="deliveryForm.collaboratorPhoneNumber")
      div.w-50
        b-form-group(label-for='collaboratorSecondaryPhoneNumber' style='flex:1')
          validation-provider(#default='{ errors }' name='collaboratorSecondaryPhoneNumber')
            b-form-input(placeholder="Téléphone secondaire" v-model="deliveryForm.collaboratorSecondaryPhoneNumber")
  .card-info(v-else-if="deliveryForm.collaboratorId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
    div(v-if="!editMode")
      .card-tools.pr-0(v-if="showCardTool")
        feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier le collaborateur'" @click="editMode=true")
        feather-icon(icon="XIcon" size="18" @click="resetCollaborator"  v-b-tooltip.hover.top="'Changer de collaborateur'")
      p.mb-50.text-primary.font-weight-bold {{ deliveryForm.collaboratorFirstName }} {{ deliveryForm.collaboratorLastName }}
      div
        span {{ deliveryForm.collaboratorMail ? "E-mail : " + deliveryForm.collaboratorMail : "" }}
      div
        span {{ deliveryForm.collaboratorPhoneNumber ? "Tél : " + deliveryForm.collaboratorPhoneNumber : "" }}
      div
        span {{ deliveryForm.collaboratorSecondaryPhoneNumber ? "Tél secondaire: " + deliveryForm.collaboratorSecondaryPhoneNumber : "" }}
.quote-card(v-else)
  .card-info
    p.mb-50.text-primary.font-weight-bold {{ deliveryForm.collaboratorFirstName }} {{ deliveryForm.collaboratorLastName }}
    div
      span {{ deliveryForm.collaboratorMail ? "E-mail : " + deliveryForm.collaboratorMail : "" }}
    div
      span {{ deliveryForm.collaboratorPhoneNumber ? "Tél : " + deliveryForm.collaboratorPhoneNumber : "" }}
    div
      span {{ deliveryForm.collaboratorSecondaryPhoneNumber ? "Tél secondaire: " + deliveryForm.collaboratorSecondaryPhoneNumber : "" }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BFormInput, BFormGroup, BButton } from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import vSelect from "vue-select";
import { mask } from "vue-the-mask";

export default {
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editMode: false,
      showCardTool: false,
    };
  },
  async created() {
    await this.getCollaborators({});
  },
  computed: {
    ...mapGetters(["isLoadingCollaboratorsList", "collaboratorsList"]),
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateDeliveryForm", "getCollaborators"]),

    newCollaborator() {
      this.$router.push({
        name: "new-collaborator",
        params: { routeOrigine: "edit-delivery-form/" + this.deliveryForm.id },
      });
    },
    setCollaborator(value) {
      let collaborator = this.collaboratorsList.find((el) => el.id == value);
      this.deliveryForm.collaboratorLastName = collaborator.lastName;
      this.deliveryForm.collaboratorFirstName = collaborator.firstName;
      this.deliveryForm.collaboratorPhoneNumber = collaborator.phoneNumber;
      this.deliveryForm.collaboratorMail = collaborator.email;
      this.saveCollaborator();
    },
    saveCollaborator() {
      this.editMode = false;
      this.updateDeliveryForm({
        deliveryForm: this.deliveryForm,
        loading: false,
      });
    },
    resetCollaborator() {
      this.deliveryForm.collaboratorLastName = "";
      this.deliveryForm.collaboratorFirstName = "";
      this.deliveryForm.collaboratorPhoneNumber = "";
      this.deliveryForm.collaboratorMail = "";
      this.deliveryForm.collaboratorId = null;
      this.saveCollaborator();
    },
  },
  directives: {
    mask,
  },
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
