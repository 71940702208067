<template lang="pug">
  .quote-card(v-if="!preview")
      b-form-group(v-if="!document.companyCustomerId" label-for='customer')
        validation-provider(#default='{ errors }' name='Société')
          v-select#company(ref="selectCompany" :loading='isLoadingCompaniesList' :state='errors.length > 0 ? false : null' :value='document.companyCustomerName' @input='setValue' :options='options.map((elem)=>{return {label:elem.name, value:elem.id}})' :placeholder='placeholder')
            template(v-slot:no-options='')
              template  Aucun r&eacute;sultat trouv&eacute;
            li.border-bottom.p-1.py-50(slot='list-header')
              b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newCustomer()' variant='primary' size='sm')
                | + Nouveau sous-traitant
                span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                  | open_in_new
          small.text-danger {{ errors[0] }}
      div(v-if="editMode")
        div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
          span.text-primary.font-weight-bold Société :
          span.cursor-pointer.text-primary(v-if="editMode" @click='validEditMode') Enregistrer
        div.mb-1
          b-form-group(label-for='companyName' style='flex:1')
            validation-provider(#default='{ errors }' name='companyName')
              b-form-input(placeholder="Nom de la société" v-model="documentEdit.companyCustomerName")
        div.mb-1(v-if="company && company.length")
          b-form-group(v-if="documentEdit.companyCustomerId" label-for='address')
            validation-provider(#default='{ errors }' name='address')
              v-select#addresses(ref="selectAddress" item-text="label" :loading='isLoadingCompany' :state='errors.length > 0 ? false : null' :value='documentEdit.companyCustomerAddressLabel' @input='setCompanyCustomerAddress' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='company' placeholder='Sélectionner une adresse')
                template(v-slot:no-options='')
                  template  Aucun r&eacute;sultat trouv&eacute;
        div.mb-1
          b-form-group(label-for='companyCode' style='flex:1')
            validation-provider(#default='{ errors }' name='companyCode')
              b-form-input(placeholder="Code de la société" v-model="documentEdit.companyCustomerCode")
        div.mb-1
          b-form-group(label-for='companyAddress' style='flex:1')
            validation-provider(#default='{ errors }' name='companyAddress')
              b-form-input(placeholder="Adresse" v-model="documentEdit.companyCustomerAddress") 
        div.mb-1
          b-form-group(label-for='companyAddressComplement' style='flex:1')
            validation-provider(#default='{ errors }' name='companyAddressComplement')
              b-form-input(placeholder="Complément d'adresse" v-model="documentEdit.companyCustomerAddressComplement") 
        div.mb-1.d-flex
          div.w-25
            b-form-group.pr-1(label-for='companyZipCode' style='flex:1')
              validation-provider(#default='{ errors }' name='companyZipCode')
                b-form-input(placeholder="CP" v-model="documentEdit.companyCustomerZipCode")
          div.w-75
            b-form-group(label-for='companyCity' style='flex:1')
              validation-provider(#default='{ errors }' name='companyCity')
                b-form-input(placeholder="Ville" v-model="documentEdit.companyCustomerCity")
        div.mb-1
          b-form-group(label-for='companyCountry' style='flex:1')
            validation-provider(#default='{ errors }' name='companyCountry')
              b-form-input(placeholder="Pays" v-model="documentEdit.companyCustomerCountry")
        .mb-1.d-flex 
          b-form-group(label-for='companyMail' style='flex:1')
            validation-provider(#default='{ errors }' name='companyMail')
              b-form-input(placeholder="Mail" v-model="documentEdit.companyCustomerMail")
        .mb-1.d-flex 
          b-form-group(label-for='companyPhoneNumber' style='flex:1')
            validation-provider(#default='{ errors }' name='companyPhoneNumber')
              b-form-input(placeholder="Numéro de téléphone" v-model="documentEdit.companyCustomerPhoneNumber")
        .mb-1.d-flex 
          b-form-group(label-for='companySiret' style='flex:1')
            validation-provider(#default='{ errors }' name='companySiret')
              b-form-input(placeholder="Siret" v-model="documentEdit.companyCustomerSiret")
        .mb-1.d-flex 
          b-form-group(label-for='companyTva' style='flex:1')
            validation-provider(#default='{ errors }' name='companyTva')
              b-form-input(placeholder="N° TVA intra" v-model="documentEdit.companyCustomerTva")
      .card-info(v-else-if="document.companyCustomerId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
        div(v-if="!editMode")
          .card-tools.pr-0(v-if="showCardTool")
            feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier la société'" @click="setEditMode()")
            feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de société'")
          p.mb-50.text-primary.font-weight-bold {{ document.companyCustomerCode && deliveryFormOption.showCompanyCustomerCode ? document.companyCustomerCode + " - " : "" }} {{ document.companyCustomerName }}
          div(v-if="document.companyCustomerAddressLabel != null && deliveryFormOption.showCompanyCustomerAddressLabel")
            span {{ document.companyCustomerAddressLabel }}
          div(v-if="document.companyCustomerAddress != null")
            span {{ document.companyCustomerAddress }}
          div(v-if="document.companyCustomerAddressComplement != null")
            span {{ document.companyCustomerAddressComplement }}
          div(v-if="document.companyCustomerZipCode != null || document.companyCustomerCity != null")
            span {{ document.companyCustomerZipCode }} {{ document.companyCustomerCity }}
          div(v-if="document.companyCustomerCountry != null")
            span {{ document.companyCustomerCountry }}
          div(v-if="deliveryFormOption.showCompanyCustomerMail")
            span Email : {{ document.companyCustomerMail ? document.companyCustomerMail : "Non renseigné" }}
          div(v-if="deliveryFormOption.showCompanyCustomerPhoneNumber")
            span Téléphone : {{ document.companyCustomerPhoneNumber ? document.companyCustomerPhoneNumber : "Non renseigné" }}
          div(v-if="deliveryFormOption.showCompanyCustomerSiret")
            span Siret : {{ document.companyCustomerSiret ? document.companyCustomerSiret : "Non renseigné" }}
          div(v-if="deliveryFormOption.showCompanyCustomerTvaNumber")
            span N° TVA intra : {{ document.companyCustomerTva ? document.companyCustomerTva : "Non renseigné" }}
  .quote-card(v-else)
      .card-info
        p.mb-50.text-primary.font-weight-bold  {{ document.companyCustomerCode && deliveryFormOption.showCompanyCustomerCode ? document.companyCustomerCode + " - " : "" }} {{ document.companyCustomerName  }}
        div(v-if="document.companyCustomerAddressLabel != null && deliveryFormOption.showCompanyCustomerAddressLabel")
          span {{ document.companyCustomerAddressLabel }}
        div(v-if="document.companyCustomerAddress != null")
          span {{ document.companyCustomerAddress }}
        div(v-if="document.companyCustomerAddressComplement != null")
          span {{ document.companyCustomerAddressComplement }}
        div(v-if="document.companyCustomerZipCode != null || document.companyCustomerCity != null")
          span {{ document.companyCustomerZipCode }} {{ document.companyCustomerCity }}
        div(v-if="document.companyCustomerCountry != null")
          span {{ document.companyCustomerCountry }}
        div(v-if="deliveryFormOption.showCompanyCustomerMail")
          span Email : {{ document.companyCustomerMail ? document.companyCustomerMail : "Non renseigné"}}
        div(v-if="deliveryFormOption.showCompanyCustomerPhoneNumber")
          span Téléphone : {{ document.companyCustomerPhoneNumber ? document.companyCustomerPhoneNumber : "Non renseigné"}}
        div(v-if="deliveryFormOption.showCompanyCustomerSiret")
          span Siret : {{ document.companyCustomerSiret ? document.companyCustomerSiret : "Non renseigné"}}
        div(v-if="deliveryFormOption.showCompanyCustomerTvaNumber")
          span N° TVA intra : {{ document.companyCustomerTva ? document.companyCustomerTva : "Non renseigné"}}
  </template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
    mask,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      editMode: false,
      documentEdit: {},
      company: null,
    };
  },
  created() {
    if (this.document.companyCustomerId) {
      this.$store
        .dispatch("getCompanyById", {
          companyId: this.document.companyCustomerId,
        })
        .then((res) => {
          this.company = this.affectLabelAdress(res);
        });
    }
  },
  computed: {
    ...mapGetters([
      "isLoadingCompaniesList",
      "deliveryFormOption",
      "isLoadingCompany",
    ]),
    document: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["getCompanies"]),
    validModal(editCompany) {
      if (editCompany) {
        const updateData = {
          id: this.document.companyCustomerId,
          name: this.documentEdit.companyCustomerName,
          firstName: this.documentEdit.companyCustomerFirstName,
          lastName: this.documentEdit.companyCustomerLastName,
          address: this.documentEdit.companyCustomerAddress,
          addressComplement: this.documentEdit.companyCustomerAddressComplement,
          zipCode: this.documentEdit.companyCustomerZipCode,
          city: this.documentEdit.companyCustomerCity,
          country: this.documentEdit.companyCustomerCountry,
          phoneNumber: this.documentEdit.companyCustomerPhoneNumber,
          mail: this.documentEdit.companyCustomerMail,
          tvaNumber: this.documentEdit.companyCustomerTva,
          siret: this.documentEdit.companyCustomerSiret,
        };
        this.$store
          .dispatch("getCompanyById", {
            companyId: this.document.companyCustomerId,
          })
          .then((res) => {
            this.$store.dispatch("updateCompany", {
              company: {
                ...res,
                ...updateData,
                companyTypeId: res.companyType.id,
              },
            });
          });
      }
      let company = {
        id: this.documentEdit.companyId,
        name: this.documentEdit.companyName,
        label: this.documentEdit.companyAddressLabel,
        addressId: this.documentEdit.companyAddressId,
        address: this.documentEdit.companyAddress,
        addressComplement: this.documentEdit.companyAddressComplement,
        zipCode: this.documentEdit.companyZipCode,
        city: this.documentEdit.companyCity,
        country: this.documentEdit.companyCountry,
        siret: this.documentEdit.companySiret,
        tvaNumber: this.documentEdit.companyTva,
        phoneNumber: this.documentEdit.companyPhoneNumber,
        email: this.documentEdit.companyMail,
      };
      this.$emit("setSelectCompanyAddress", company);
      this.document = this.documentEdit;
      this.editMode = false;
    },
    validEditMode() {
      this.validModal(false);
    },
    setEditMode() {
      this.editMode = true;
      // copy document into new variable
      this.documentEdit = JSON.parse(JSON.stringify(this.document));
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        this.$emit(
          "setValue",
          this.options.find((elem) => {
            return elem.id == value.value;
          })
        );
      } else {
        this.$emit("setValue", value);
      }
    },
    setCompanyCustomerAddress(value) {
      if (value) {
        this.documentEdit.companyCustomerAddressId = value.id;
        this.documentEdit.companyCustomerAddressLabel = value.label;
        this.documentEdit.companyCustomerAddress = value.address;
        this.documentEdit.companyCustomerAddressComplement =
          value.addressComplement;
        this.documentEdit.companyCustomerZipCode = value.zipCode;
        this.documentEdit.companyCustomerCity = value.city;
        this.documentEdit.companyCustomerCountry = value.country;
        this.documentEdit.companyCustomerSiret = value.siret;
        this.documentEdit.companyCustomerTva = this.document.companyCustomerTva;
        this.document.companyCustomerMail = this.document.companyCustomerMail;
        this.document.companyCustomerPhoneNumber =
          this.document.companyCustomerPhoneNumber;
        this.document.companyCustomerCode = this.document.companyCustomerCode;
      } else {
        this.documentEdit.companyCustomerAddressId = null;
        this.documentEdit.companyCustomerAddressLabel = null;
        this.documentEdit.companyCustomerAddress = null;
        this.documentEdit.companyCustomerAddressComplement = null;
        this.documentEdit.companyCustomerZipCode = null;
        this.documentEdit.companyCustomerCity = null;
        this.documentEdit.companyCustomerCountry = null;
        this.documentEdit.companyCustomerSiret = null;
        this.documentEdit.companyCustomerTva = null;
        this.documentEdit.companyCustomerMail = null;
        this.documentEdit.companyCustomerPhoneNumber = null;
        this.documentEdit.companyCustomerCode = null;
      }
    },
    resetValue() {
      let value = {
        id: null,
        name: null,
        firstName: null,
        lastName: null,
        address: null,
        addressComplement: null,
        city: null,
        zipCode: null,
        country: null,
        email: null,
        phoneNumber: null,
        tva: null,
        siret: null,
      };
      this.value = value;
      this.setValue(value);
      this.document.companyCustomerId = null;
      this.document.companyCustomerName = null;
      this.document = {
        ...this.document,
        affairId: null,
        affairName: null,
        affairAddress: null,
        affairAddressComplement: null,
        affairZipCode: null,
        affairCity: null,
        affairCountry: null,
      };
    },
    newCustomer() {
      this.$router.push({ path: "/directory/new-company/clients/7" });
    },
    affectLabelAdress(company) {
      return company?.addresses.map((address, index) => {
        if (!address.label) {
          return { ...address, label: `Adresse ${index + 1}` };
        } else {
          return address;
        }
      });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
