<template lang="pug">
div(v-if="deliveryFormHeader[dataKey].inputType == 1")
  TextCol(@emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus")
div(v-else-if="deliveryFormHeader[dataKey].inputType == 2")
  NumberCol(@emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus")
div(v-else-if="deliveryFormHeader[dataKey].inputType == 10")
  SelectCol(@emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus") 
div(v-else-if="deliveryFormHeader[dataKey].inputType == 24")
  SelectCatalog(@emitEditFocus="changeEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index") 
</template>

<script>
import SelectCol from "@/components/purchase/delivery-form/builder/table/cols/SelectCol.vue";
import TextCol from "@/components/purchase/delivery-form/builder/table/cols/TextCol.vue";
import NumberCol from "@/components/purchase/delivery-form/builder/table/cols/NumberCol.vue";
import SelectCatalog from "@/components/purchase/delivery-form/builder/table/cols/SelectCatalog.vue";

export default {
  data() {
    return {};
  },
  props: {
    mouseOn: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      required: true,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    changeEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    //   emitEditFocus(res) {
    //     this.$emit("mainEmitFocus", res);
    //   }
  },
  components: {
    SelectCol,
    TextCol,
    NumberCol,
    SelectCatalog,
  },
};
</script>
