<template lang="pug">
.quote-card(v-if="!preview")
    b-form-group(v-if="!document.companySubContractorId" label-for='customer')
      validation-provider(#default='{ errors }' name='Société')
        v-select#company(ref="selectCompany" :loading='isLoadingCompaniesList' :state='errors.length > 0 ? false : null' :value='document.companySubContractorName' @input='setValue' :options='options.map((elem)=>{return {label:elem.name, value:elem.id}})' :placeholder='placeholder')
          template(v-slot:no-options='')
            template  Aucun r&eacute;sultat trouv&eacute;
          li.border-bottom.p-1.py-50(slot='list-header')
            b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newCustomer()' variant='primary' size='sm')
              | + Nouveau sous-traitant
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
        small.text-danger {{ errors[0] }}
    div(v-if="editMode")
      div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
        span.text-primary.font-weight-bold Société :
        span.cursor-pointer.text-primary(v-if="editMode" @click='validEditMode') Enregistrer
      div.mb-1
        b-form-group(label-for='companyName' style='flex:1')
          validation-provider(#default='{ errors }' name='companyName')
            b-form-input(placeholder="Nom de la société" v-model="documentEdit.companySubContractorName")
      div.mb-1(v-if="company && company.addresses && company.addresses.length")
        b-form-group(v-if="document.companySubContractorId" label-for='address')
          validation-provider(#default='{ errors }' name='address')
            v-select#addresses(ref="selectAddress" item-text="label" :loading='isLoadingCompany' :state='errors.length > 0 ? false : null' :value='documentEdit.companySubContractorAddressLabel' @input='setCompanySubContractorAddress' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='company.addresses' placeholder='Sélectionner une adresse')
              template(v-slot:no-options='')
                template  Aucun r&eacute;sultat trouv&eacute;
      div.mb-1
        b-form-group(label-for='companyAddress' style='flex:1')
          validation-provider(#default='{ errors }' name='companyAddress')
            b-form-input(placeholder="Adresse" v-model="documentEdit.companySubContractorAddress") 
      div.mb-1
        b-form-group(label-for='companyAddressComplement' style='flex:1')
          validation-provider(#default='{ errors }' name='companyAddressComplement')
            b-form-input(placeholder="Adresse" v-model="documentEdit.companySubContractorAddressComplement") 
      div.mb-1.d-flex
        div.w-25
          b-form-group.pr-1(label-for='companyZipCode' style='flex:1')
            validation-provider(#default='{ errors }' name='companyZipCode')
              b-form-input(placeholder="CP"  v-model="documentEdit.companySubContractorZipCode")
        div.w-75
          b-form-group(label-for='companyCity' style='flex:1')
            validation-provider(#default='{ errors }' name='companyCity')
              b-form-input(placeholder="Ville" v-model="documentEdit.companySubContractorCity")
      div.mb-1
        b-form-group(label-for='companyCountry' style='flex:1')
          validation-provider(#default='{ errors }' name='companyCountry')
            b-form-input(placeholder="Pays" v-model="documentEdit.companySubContractorCountry")
      .mb-1.d-flex 
        b-form-group(label-for='companyMail' style='flex:1')
          validation-provider(#default='{ errors }' name='companyMail')
            b-form-input(placeholder="Email" v-model="documentEdit.companySubContractorMail")
      .mb-1.d-flex 
        b-form-group(label-for='companyPhoneNumber' style='flex:1')
          validation-provider(#default='{ errors }' name='companyPhoneNumber')
            b-form-input(placeholder="Téléphone" v-model="documentEdit.companySubContractorPhoneNumber")
      .mb-1.d-flex 
        b-form-group(label-for='companySiret' style='flex:1')
          validation-provider(#default='{ errors }' name='companySiret')
            b-form-input(placeholder="Siret" v-model="documentEdit.companySubContractorSiret")
      .mb-1.d-flex 
        b-form-group(label-for='companyTva' style='flex:1')
          validation-provider(#default='{ errors }' name='companyTva')
            b-form-input(placeholder="N° TVA intra" v-model="documentEdit.companySubContractorTva")
    .card-info(v-else-if="document.companySubContractorId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      div(v-if="!editMode")
        .card-tools.pr-0(v-if="showCardTool")
          feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier la société'" @click="setEditMode()")
          feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de société'")
        p.mb-50.text-primary.font-weight-bold {{ document.companySubContractorCode && deliveryFormOption.showCompanySubContractorCode ? document.companySubContractorCode + " - " : "" }} {{ document.companySubContractorName  }}
        div(v-if="document.companySubContractorAddressLabel != null && deliveryFormOption.showCompanySubContractorAddressLabel")
          span {{ document.companySubContractorAddressLabel }}
        div(v-if="document.companySubContractorAddress != null")
          span {{ document.companySubContractorAddress }}
        div(v-if="document.companySubContractorAddressComplement != null")
          span {{ document.companySubContractorAddressComplement }}
        div(v-if="document.companySubContractorZipCode != null || document.companySubContractorCity != null")
          span {{ document.companySubContractorZipCode }} {{ document.companySubContractorCity }}
        div(v-if="document.companySubContractorCountry != null")
          span {{ document.companySubContractorCountry }}
        div(v-if="deliveryFormOption.showCompanySubContractorMail")
          span E-mail : {{ document.companySubContractorMail ? document.companySubContractorMail : "Non renseigné" }}
        div(v-if="deliveryFormOption.showCompanySubContractorPhoneNumber")
          span Tél : {{ document.companySubContractorPhoneNumber ? document.companySubContractorPhoneNumber : "Non renseigné" }}
        div(v-if="deliveryFormOption.showCompanySubContractorSiret")
          span Siret : {{ document.companySubContractorSiret ? document.companySubContractorSiret : "Non renseigné" }}
        div(v-if="deliveryFormOption.showCompanySubContractorTvaNumber")
          span N° TVA intra : {{ document.companySubContractorTva ? document.companySubContractorTva : "Non renseigné"}}
.quote-card(v-else)
    .card-info
      p.mb-50.text-primary.font-weight-bold {{ document.companySubContractorCode && deliveryFormOption.showCompanySubContractorCode ? document.companySubContractorCode + " - " : "" }} {{ document.companySubContractorName  }}
      div(v-if="document.companySubContractorAddressLabel != null && deliveryFormOption.showCompanySubContractorAddressLabel")
        span {{ document.companySubContractorAddressLabel }}
      div(v-if="document.companySubContractorAddress != null")
        span {{ document.companySubContractorAddress }}
      div(v-if="document.companySubContractorAddressComplement != null")
        span {{ document.companySubContractorAddressComplement }}
      div(v-if="document.companySubContractorZipCode != null || document.companySubContractorCity != null")
        span {{ document.companySubContractorZipCode }} {{ document.companySubContractorCity }}
      div(v-if="document.companySubContractorCountry != null")
        span {{ document.companySubContractorCountry }}
      div(v-if="deliveryFormOption.showCompanySubContractorMail")
        span E-mail : {{ document.companySubContractorMail ? document.companySubContractorMail : "Non renseigné" }}
      div(v-if="deliveryFormOption.showCompanySubContractorPhoneNumber")
        span Tél : {{ document.companySubContractorPhoneNumber ? document.companySubContractorPhoneNumber : "Non renseigné" }}
      div(v-if="deliveryFormOption.showCompanySubContractorSiret")
        span Siret : {{ document.companySubContractorSiret ? document.companySubContractorSiret : "Non renseigné" }}
      div(v-if="deliveryFormOption.showCompanySubContractorTvaNumber")
        span N° TVA intra : {{ document.companySubContractorTva ? document.companySubContractorTva : "Non renseigné"}}
</template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
    mask,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      editMode: false,
      documentEdit: {},
      company: null,
    };
  },
  created() {
    if (this.document.companySubContractorId) {
      this.$store
        .dispatch("getCompanyById", {
          companyId: this.document.companySubContractorId,
        })
        .then((res) => {
          this.company = res;
        });
    }
  },
  computed: {
    ...mapGetters([
      "isLoadingCompaniesList",
      "isLoadingCompany",
      "deliveryFormOption",
    ]),
    document: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["getCompanies"]),
    validModal(editCompany) {
      if (editCompany) {
        const updateData = {
          id: this.document.companySubContractorId,
          name: this.documentEdit.companySubContractorName,
          firstName: this.documentEdit.companySubContractorFirstName,
          lastName: this.documentEdit.companySubContractorLastName,
          address: this.documentEdit.companySubContractorAddress,
          addressComplement:
            this.documentEdit.companySubContractorAddressComplement,
          zipCode: this.documentEdit.companySubContractorZipCode,
          city: this.documentEdit.companySubContractorCity,
          country: this.documentEdit.companySubContractorCountry,
          phoneNumber: this.documentEdit.companySubContractorPhoneNumber,
          mail: this.documentEdit.companySubContractorMail,
          tvaNumber: this.documentEdit.companySubContractorTva,
          siret: this.documentEdit.companySubContractorSiret,
        };
        this.$store
          .dispatch("getCompanyById", {
            companyId: this.document.companySubContractorId,
          })
          .then((res) => {
            this.$store.dispatch("updateCompany", {
              company: {
                ...res,
                ...updateData,
                companyTypeId: res.companyType.id,
              },
            });
          });
      }
      this.document = this.documentEdit;
      this.editMode = false;
    },
    setCompanySubContractorAddress(value) {
      if (value) {
        this.documentEdit.companySubContractorAddressId = value.id;
        this.documentEdit.companySubContractorAddressLabel = value.label;
        this.documentEdit.companySubContractorAddress = value.address;
        this.documentEdit.companySubContractorAddressComplement =
          value.addressComplement;
        this.documentEdit.companySubContractorZipCode = value.zipCode;
        this.documentEdit.companySubContractorCity = value.city;
        this.documentEdit.companySubContractorCountry = value.country;
        this.documentEdit.companySubContractorSiret = value.siret;
        this.documentEdit.companySubContractorTva =
          this.document.companySubContractorTva;
        this.documentEdit.companySubContractorMail =
          this.document.companySubContractorMail;
        this.documentEdit.companySubContractorPhoneNumber =
          this.document.companySubContractorPhoneNumber;
        this.documentEdit.companySubContractorCode =
          this.document.companySubContractorCode;
      } else {
        this.documentEdit.companySubContractorAddressId = null;
        this.documentEdit.companySubContractorAddressLabel = null;
        this.documentEdit.companySubContractorAddress = null;
        this.documentEdit.companySubContractorAddressComplement = null;
        this.documentEdit.companySubContractorZipCode = null;
        this.documentEdit.companySubContractorCity = null;
        this.documentEdit.companySubContractorCountry = null;
        this.documentEdit.companySubContractorSiret = null;
        this.documentEdit.companySubContractorTva = null;
        this.documentEdit.companySubContractorMail = null;
        this.documentEdit.companySubContractorPhoneNumber = null;
        this.documentEdit.companySubContractorCode = null;
      }
    },
    validEditMode() {
      this.validModal(false);
    },
    setEditMode() {
      this.editMode = true;
      // copy document into new variable
      this.documentEdit = JSON.parse(JSON.stringify(this.document));
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        this.$emit(
          "setValue",
          this.options.find((elem) => {
            return elem.id == value.value;
          })
        );
      } else {
        this.$emit("setValue", value);
      }
    },
    resetValue() {
      let value = {
        id: null,
        name: null,
        firstName: null,
        lastName: null,
        address: null,
        addressComplement: null,
        city: null,
        zipCode: null,
        country: null,
        email: null,
        phoneNumber: null,
        tvaNumber: null,
        siret: null,
      };
      this.value = value;
      this.setValue(value);
      this.document.companySubContractorId = null;
      this.document.companySubContractorName = null;
    },
    newCustomer() {
      this.$router.push({ path: "/directory/new-company/sous-traitants/8" });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
