<template>
  <div
    v-if="!preview"
    class="cursor-pointer"
    @mouseover="mouseOn = true"
    @mouseleave="mouseOn = false"
  >
    <div @click="toggleDescription(!deliveryForm.showDescription)">
      <span class="text-primary font-weight-bold no-printting">
        {{
          deliveryForm.showDescription == true
            ? "- Masquer la description"
            : "+ Ajouter une description"
        }}
      </span>
    </div>
    <quill-editor
      @focus="focusFunction(true)"
      @blur="setValue"
      :key="'description' + deliveryForm.id"
      v-if="deliveryForm.showDescription"
      class="editor quill-object"
      :class="editFocus || mouseOn ? 'edit' : 'no-edit'"
      v-model="deliveryForm.description"
      :options="editorOption"
    />
  </div>
  <div v-else>
    <span v-html="deliveryForm.description"></span>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  data() {
    return {
      mouseOn: false,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      editFocus: false,
    };
  },
  computed: {
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateDeliveryForm"]),
    focusFunction(val) {
      this.editFocus = true;
    },
    setValue() {
      this.$emit("setValue", this.deliveryForm.description);
      this.editFocus = false;
    },
    toggleDescription(val) {
      this.deliveryForm.showDescription = val;
      this.updateDeliveryForm({
        deliveryForm: this.deliveryForm,
        loading: false,
      });
    },
  },
  components: {
    quillEditor,
  },
};
</script>
