<template lang="pug">
tr(:class="line.isOption ? 'isOption' : ''" :style="line.type == 2 ? 'page-break-after: always;' : ''")
  DeliveryFormPreviewDetailCommonLine.m-0(v-for="(key, i) in Object.keys(deliveryFormHeader)" :key="i"  :dataKey="key" v-if="deliveryFormHeader[key].display && (line.type == 7 || line.type == 8 || line.type == 9  || line.type == 10)" :class="deliveryFormHeader[key].class" :line="line" :deliveryFormHeader="deliveryFormHeader" :editFocus="editFocus" :edit="edit") 
  DeliveryFormPreviewDetailSectionLine.m-0(v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 1 || line.type == 2" :line="line" :class="deliveryFormHeader[key].class" :colspan="setColspanSection(deliveryFormHeader[key])" :deliveryFormHeader="deliveryFormHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
  DeliveryFormPreviewDetailSubTotalLine.m-0(v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 5" :line="line" :class="deliveryFormHeader[key].class" :colspan="setColspanSubTotalLine(deliveryFormHeader[key])"  :deliveryFormHeader="deliveryFormHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
  DeliveryFormPreviewDetailTextLine.m-0(v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 6" :class="deliveryFormHeader[key].class" :colspan="setColspanSection(deliveryFormHeader[key])" :line="line" :deliveryFormHeader="deliveryFormHeader" :editFocus="editFocus" :edit="edit")
  DeliveryFormDetailBreakPageLine.m-0(v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 4"  :line="line" :deliveryFormHeader="deliveryFormHeader" :colspan="setColspanAll(deliveryFormHeader[key])" :edit="edit" :editFocus="editFocus")
  DeliveryFormPreviewDetailBreakLine.m-0(v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 3"  :line="line" :deliveryFormHeader="deliveryFormHeader" :colspan="setColspanAll(deliveryFormHeader[key])" :edit="edit" :editFocus="editFocus")
</template>

<script>
import DeliveryFormPreviewDetailCommonLine from "@/components/purchase/delivery-form/preview/table/lines/DeliveryFormPreviewDetailCommonLine.vue";
import DeliveryFormPreviewDetailSectionLine from "@/components/purchase/delivery-form/preview/table/lines/DeliveryFormPreviewDetailSectionLine.vue";
import DeliveryFormPreviewDetailTextLine from "@/components/purchase/delivery-form/preview/table/lines/DeliveryFormPreviewDetailTextLine.vue";
import DeliveryFormPreviewDetailBreakPageLine from "@/components/purchase/delivery-form/preview/table/lines/DeliveryFormPreviewDetailBreakPageLine.vue";
import DeliveryFormPreviewDetailBreakLine from "@/components/purchase/delivery-form/preview/table/lines/DeliveryFormPreviewDetailBreakLine.vue";
import DeliveryFormPreviewDetailPriceLine from "@/components/purchase/delivery-form/preview/table/lines/DeliveryFormPreviewDetailPriceLine.vue";
import DeliveryFormPreviewDetailSubTotalLine from "@/components/purchase/delivery-form/preview/table/lines/DeliveryFormPreviewDetailSubTotalLine.vue";
export default {
  data() {
    return {
      edit: false,
      editFocus: false,
    };
  },
  computed: {
    deliveryFormHeader: {
      get() {
        return this.$store.getters.getDeliveryFormHeader;
      },
      set(val) {
        this.$store.commit("setupHeaderDeliveryForm", {
          ...deliveryFormHeader,
          val,
        });
      },
    },
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
    columnText() {
      if (this.deliveryForm.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  watch: {
    editFocus(val) {
      if (val == false) {
        this.edit = false;
      }
    },
  },
  methods: {
    setColspanAll(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return Object.entries(this.deliveryFormHeader).filter(
          (el) => el[1].display == true
        ).length;
      } else {
        return 1;
      }
    },
    setColspanSection(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.deliveryFormHeader).filter(
            (el) => el[1].display == true
          ).length - 1
        );
      } else {
        return 1;
      }
    },
    setColspanSubTotalLine(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.deliveryFormHeader).filter(
            (el) => el[1].display == true
          ).length - 2
        );
      } else {
        return 1;
      }
    },
  },
  components: {
    DeliveryFormPreviewDetailCommonLine,
    DeliveryFormPreviewDetailSectionLine,
    DeliveryFormPreviewDetailTextLine,
    DeliveryFormPreviewDetailBreakPageLine,
    DeliveryFormPreviewDetailBreakLine,
    DeliveryFormPreviewDetailPriceLine,
    DeliveryFormPreviewDetailSubTotalLine,
  },
};
</script>
