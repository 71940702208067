<template lang="pug">
div.builder-header.px-2
  div
    ul#pills-tab.nav.nav-pill(role='tablist')
      li.nav-item(v-if="deliveryForm.status < 3" @click="changeDeliveryFormTabActive('delivery-form-edit')")
        div.nav-link.cursor-pointer.text-center(:class="deliveryFormTabActive == 'delivery-form-edit' ? 'active' : ''")
          feather-icon(icon="Edit2Icon" size="15") 
          |  Edition
      li.nav-item(v-if="checkIfPreviewIsPossible()" @click="showPreviewPdf")
        div.nav-link.cursor-pointer.text-center(:class="deliveryFormTabActive == 'delivery-form-pdf' ? 'active' : ''")
          feather-icon(icon="EyeIcon" size="15") 
          |  Prévisualisation
      li.nav-item(v-if="haveGedModule" @click="changeDeliveryFormTabActive('attachments')")
        div.nav-link.cursor-pointer.text-center(:class="deliveryFormTabActive == 'attachments' ? 'active' : ''")
          feather-icon(icon="FolderIcon" size="15") 
          |  Documents
      li.nav-item(@click="changeDeliveryFormTabActive('internal-note')")
        div.nav-link.cursor-pointer.text-center(:class="deliveryFormTabActive == 'internal-note' ? 'active' : ''")
          feather-icon(icon="LockIcon" size="15") 
          |  Notes
  div.d-flex.align-items-center.ml-auto
    .form-group.form-check.mb-0.mr-1.tab-control.builderControls(v-if="deliveryFormTabActive=='delivery-form-edit'" data-tab='delivery-form-edit')
      .d-flex.align-items-center
        b-dropdown.mr-1#dropdown-form(ref='dropdown' right='' variant='outline-secondary' text='Options')
          b-dropdown-form.dropdown-options
            h6.mt-1 Edition
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="deliveryFormOption.showReferenceColumn")
              | Afficher la colonne des références
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.isReverseCharge")
              | Autoliquidation
            h6.mt-1 Prévisualisation 
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideInstitutionLogo")
              | Masquer mon logo
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideInstitutionName")
              | Masquer ma raison sociale
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideInstitutionAddress")
              | Masquer mon adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideAffair")
              | Masquer l'affaire
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.showAffairCode")
              | Masquer le code de l'affaire
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType != 2" @change="saveAction(false)" v-model="deliveryFormOption.hideStorageLocation")
              | Masquer le lieu de stockage
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideDestockingLocation")
              | Masquer le lieu de déstockage
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideCollaborator")
              | Masquer le collaborateur
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideCompanySubContractor")
              | Masquer le sous-straitant
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideTotal")
              | Afficher le total
            h6.mt-1 Document 
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideUser")
              | Masquer le contact
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="deliveryFormOption.hideIndexColumn")
              | Masquer la colonne des index
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="deliveryFormOption.hideOutPutQuantity")
              | Masquer la colonne des quantitées sorties
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="deliveryFormOption.hideUnitColumn")
              | Masquer la colonne des unités
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="deliveryFormOption.hideUnitPriceHtColumn")
              | Masquer la colonne de prix unitaire HT
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="deliveryFormOption.hideReferencielTvaColumn")
              | Masquer la colonne des TVA
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="deliveryFormOption.hidePriceHtColumn")
              | Masquer la colonne des totaux HT
            h6.mt-1 Client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.hideCompanyCustomer")
              | Masquer le client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.showCompanyCustomerCode")
              | Afficher le code du client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.showCompanyCustomerAddressLabel")
              | Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.showCompanyCustomerMail")
              | Afficher le mail
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.showCompanyCustomerPhoneNumber")
              | Afficher le numéro de téléphone
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.showCompanyCustomerSiret")
              | Afficher le numéro de SIRET
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="deliveryFormOption.showCompanyCustomerTvaNumber")
              | Afficher le numéro de TVA intracommunautaire
            h6.mt-1(v-if="deliveryForm.deliveryType == 2") Sous-traitant
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType == 2" @change="saveAction(false)" v-model="deliveryFormOption.hideCompanySubContractor")
              | Masquer le sous-traitant
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType == 2" @change="saveAction(false)" v-model="deliveryFormOption.showCompanySubContractorCode")
              | Afficher le code du sous-traitant
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType == 2" @change="saveAction(false)" v-model="deliveryFormOption.showCompanySubContractorAddressLabel")
              | Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType == 2" @change="saveAction(false)" v-model="deliveryFormOption.showCompanySubContractorMail")
              | Afficher le mail
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType == 2" @change="saveAction(false)" v-model="deliveryFormOption.showCompanySubContractorPhoneNumber")
              | Afficher le numéro de téléphone
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType == 2" @change="saveAction(false)" v-model="deliveryFormOption.showCompanySubContractorSiret")
              | Afficher le numéro de SIRET
            b-form-checkbox.checkbox-options(v-if="deliveryForm.deliveryType == 2" @change="saveAction(false)" v-model="deliveryFormOption.showCompanySubContractorTvaNumber")
              | Afficher le numéro de TVA intracommunautaire


        .vl
    div(v-if="deliveryForm.status == 0 || deliveryForm.status == 1")
      b-button.mr-1.btn-invoice-builder-header(@click="cancel" variant="outline-danger")
        | Supprimer
    ejs-tooltip.tooltipcontainer(opensOn='Custom' v-on:mouseover.native='customOpen' v-on:mouseleave.native='customClose' :content="!deliveryForm.collaboratorId && deliveryForm.deliveryType==2 ? 'Collaborateur obligatoire' : 'Certaines lignes ne sont pas valides'" :cssClass="cssClass" ref='tooltip')
      div.d-flex.align-items-center.ml-auto(v-if="(deliveryForm.status < 3) && deliveryFormDetails.length > 0")
        b-button-group.mr-1
          b-dropdown.dropdown-options(text='Finaliser le document' variant='success' dropup :disabled="productListIncomplete || (!deliveryForm.collaboratorId && deliveryForm.deliveryType==2)")
            b-dropdown-item.cursor-pointer(@click="finalizedDocument(1, false)" :disabled="deliveryForm.status >= 2" :style="deliveryForm.status >= 2 ? 'opacity:0.5' : ''") 
              span.chip.justify-content-start
                span.point.bg-warning
                span.label.text-warning Passer en validation
            b-dropdown-item.cursor-pointer(@click="finalizedDocument(2, false)" :disabled="deliveryForm.status >= 3" :style="deliveryForm.status >= 3 ? 'opacity:0.5' : ''") 
              span.chip.justify-content-start
                span.point.bg-primary
                span.label.text-primary Valider la livraison
    div
      b-button-group(v-if="deliveryForm.status < 3")
        b-button.btn-invoice-builder-header.m-0(@click="checkStatusBeforeSave(false)" variant='primary')
          | Enregistrer
        b-button.btn-invoice-builder-header.bl-white(@click="checkStatusBeforeSave(true)" style="border-left: 1px solid #ccc !important;" variant='primary')
          feather-icon(icon='LogOutIcon')
      b-button-group(v-else-if="deliveryForm.path")
        b-button.btn-invoice-builder-header.m-0(variant='primary' @click="forceFileDownload(deliveryForm.path)")
          | Télécharger
          feather-icon.ml-50(icon='DownloadIcon')
        b-button.btn-invoice-builder-header.bl-white(variant='primary' @click="cancel" style="border-left: 1px solid #f1f1f1 !important; border-top-left-radius:0px; border-bottom-left-radius:0px; ")
          | Fermer 
          feather-icon.ml-50(icon='LogOutIcon')
      b-button.btn-invoice-builder-header(v-else variant='primary' @click="cancel")
        | Fermer 
        feather-icon.ml-50(icon='LogOutIcon')
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { calculateTotalDeliveryFormLine } from "@/types/api-orisis/library/DetailDeliveryFormOperations";
import { checkFunctionUser } from "@/auth/utils.ts";
export default {
  data() {
    return {
      mandatoryInformationAlertArray: [],
      calculateTotalDeliveryFormLine,
      cssClass: "tool-tip-error",
    };
  },
  methods: {
    ...mapActions([
      "createDeliveryForm",
      "updateDeliveryForm",
      "updateDeliveryFormOption",
      "deleteDeliveryForm",
      "loopDeliveryFormDetails",
      "changeDeliveryFormTabActive",
      "setupHeaderDeliveryForm",
    ]),
    checkFunctionUser,
    showPreviewPdf() {
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeDeliveryFormTabActive("delivery-form-pdf");
      });
    },
    customOpen(e) {
      if (this.productListIncomplete) this.$refs.tooltip.open(e.target);
    },
    customClose() {
      if (this.productListIncomplete) this.$refs.tooltip.close();
    },
    async finalizedDocument(action, exit) {
      await this.checkMandatoryInformation()
        .then(async () => {
          if (action == 1) {
            await this.updateDeliveryForm({
              deliveryForm: { ...this.deliveryForm, status: 2 },
              loading: true,
            });
            if (this.deliveryFormTabActive == "delivery-form-pdf") {
              this.$emit("changePreviewKey");
            } else {
              await this.changeDeliveryFormTabActive("delivery-form-pdf");
            }
          } else if (action == 2) {
            await this.validatedDeliveryForm(exit);
            // this.executeMultipleAsyncFunctions().then(async () => {
            //   await this.validatedDeliveryForm(exit)
            // }).catch((err) => {
            //   console.error(err);
            // })
          }
        })
        .catch(async (err) => {
          await this.$swal({
            title: err,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Corriger",
            customClass: {
              confirmButton: "btn btn-outline-danger",
            },
            buttonsStyling: false,
          });
        });
    },
    async validatedDeliveryForm(exit) {
      this.$bvModal
        .msgBoxConfirm(
          "Attention, en validant votre bon de sortie, celui-ci ne sera plus modifiable. Les quantités livrées seront sorties de votre stock.",
          {
            title: "Valider la réception",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.$store.commit("SET_IS_VALIDATING_DELIVERY_FORM", true);
            await this.updateDeliveryForm({
              deliveryForm: { ...this.deliveryForm, status: 3 },
              loading: true,
            })
              .then(async () => {
                // Si la tab affiché correspond déjà à l'aperçu alor son rafrachit le composant pour que l'aperçu se regénère
                if (this.deliveryFormTabActive == "delivery-form-pdf") {
                  this.$emit("changePreviewKey");
                } else {
                  await this.changeDeliveryFormTabActive("delivery-form-pdf");
                }

                this.$store.commit("SET_IS_VALIDATING_DELIVERY_FORM", false);
              })
              .catch((err) => {
                console.error(err);
                this.$store.commit("SET_IS_VALIDATING_DELIVERY_FORM", false);
              });

            if (exit) {
              this.$tabs.close({ to: "/delivery-forms" });
            }
          }
        });
    },
    async setHeader() {
      this.setupHeaderDeliveryForm();
      this.saveAction(false);
    },
    saveAction(bool) {
      this.updateDeliveryFormOption({
        deliveryFormOption: this.deliveryFormOption,
      });
    },
    async checkStatusBeforeSave(exit) {
      await this.updateDeliveryForm({
        deliveryForm: {
          ...this.deliveryForm,
          status: this.deliveryForm.status == 0 ? 1 : this.deliveryForm.status,
        },
        loading: true,
      });
      if (exit) {
        this.$tabs.close({ to: "/delivery-forms" });
      }
    },
    checkIfPreviewIsPossible() {
      if (
        (this.deliveryForm.deliveryType == 1 &&
          this.deliveryForm.storageLocationId &&
          this.deliveryForm.destockingLocationId) ||
        ((this.deliveryForm.deliveryType == 0 ||
          this.deliveryForm.deliveryType == 2) &&
          this.deliveryForm.destockingLocationId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute("download", this.deliveryForm.documentReference);
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    checkMandatoryInformation() {
      return new Promise((resolve, reject) => {
        if (!this.deliveryForm.destockingLocationId) {
          reject("Veuillez sélectionner un point de déstockage");
        } else if (
          this.deliveryForm.companyCustomerId &&
          (this.deliveryForm.affairId == null ||
            this.deliveryForm.affairId == 0)
        ) {
          reject("Veuillez sélectionner une affaire");
        } else if (
          this.deliveryFormDetails.filter(
            (detail) =>
              (detail.type == 7 ||
                detail.type == 8 ||
                detail.type == 9 ||
                document.type == 10) &&
              (detail.reference == null || detail.reference == "")
          ).length > 0
        ) {
          reject(
            "Veuillez completer les références des lignes du bon de sortie"
          );
        } else {
          resolve(true);
        }
      });
    },
    cancel() {
      if (this.deliveryForm.status !== 0 && this.deliveryForm.status !== 1) {
        this.closeTab();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (
                this.deliveryForm.status == 0 ||
                this.deliveryForm.status == 1
              ) {
                this.deleteDeliveryForm({
                  deliveryFormIds: [this.deliveryForm.id],
                }).then(() => {
                  this.closeTab();
                });
              } else {
                this.closeTab();
              }
            }
          });
      }
    },
    closeTab() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },
  },
  computed: {
    ...mapGetters([
      "isUpdatingDeliveryForm",
      "deliveryFormTabActive",
      "isLoadingDeliveryFormPDF",
      "workspaceSelected",
      "deliveryFormOption",
    ]),
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
    deliveryFormDetails: {
      get() {
        return this.$store.getters["getDeliveryFormDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM_DETAILS", value);
      },
    },
    productListIncomplete() {
      for (let i = 0; i < this.deliveryFormDetails.length; i++) {
        const deliveryFormDetail = this.deliveryFormDetails[i];
        if (
          (!deliveryFormDetail.productId ||
            !deliveryFormDetail.reference ||
            !deliveryFormDetail.description) &&
          [7, 8, 9, 10].includes(deliveryFormDetail.type)
        ) {
          return true;
        }
      }
      return false;
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.vl {
  border-left: 1px solid #8a8e93;
  width: 1px;
  height: 16px;
  background: rgb(255, 255, 255);
}

.btn-invoice-builder-header {
  padding: 8px;
  height: 38px;
}

.bl-white {
  border-left-color: red !important;
}

.checkbox-options {
  margin-bottom: 10px;
}

.checkbox-options > label {
  font-size: 13px !important;
}
</style>
