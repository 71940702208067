<template lang="pug"> 
  td
    div(v-if="deliveryFormHeader[dataKey].inputType == 24") 
      div
        span {{ line.isOption == true ? 'Sous-total des options' : 'Sous-total' }}
    div(v-else-if="dataKey == 'subtotal'")
      div(style="text-align:center")
        span {{ formatCurrency(line.total) }}
    div(v-else-if="deliveryFormHeader[dataKey].inputType == 1")
      div
        span {{line[dataKey]}}
</template>

<script>
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    indexArray: {
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatCurrency,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
};
</script>
