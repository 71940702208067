<template lang="pug">
  td
    div(v-if="deliveryFormHeader[dataKey].inputType == 1") 
      span(v-model="line[dataKey]") 
      span {{deliveryFormHeader[dataKey].prefix }} 
      span {{ formatNumber(line[dataKey]) }}
      span {{deliveryFormHeader[dataKey].unit}}
    div(v-else-if="deliveryFormHeader[dataKey].inputType == 10") 
      SelectCol(:line="line" :canEdit="false" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :editFocus="editFocus" :preview="true") 
    div(v-else-if="deliveryFormHeader[dataKey].inputType == 24") 
      SelectCatalog(:line="line" :canEdit="false" :dataKey="dataKey" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :editFocus="editFocus" :preview="true") 
</template>

<script>
import TextCol from "@/components/purchase/delivery-form/builder/table/cols/TextCol.vue";
import SelectCatalog from "@/components/purchase/delivery-form/builder/table/cols/SelectCatalog.vue";
import SelectCol from "@/components/purchase/delivery-form/builder/table/cols/SelectCol.vue";
import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {};
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatNumber,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TextCol,
    SelectCatalog,
    SelectCol,
  },
};
</script>

<style>
.input {
  padding: 0.375rem 0.75rem;
}
.no-edit {
  border: none;
}
.form-control:disabled {
  border: 1px solid #fff;
  background-color: white;
}
</style>
