<template lang="pug">
  div(style="page-break-inside: avoid")
    .border-left.border-right.border-top.rounded-top.content-total
      div
        //- Total HT (après remise si remise)
        //- Gestion du Total TTC
        strong.d-flex.total-row Total HT
          .ml-auto.price-total
            span.totalWithoutTax {{ formatCurrency(totalDocument.totalNetHt) }}
          
      // Gestion de la TVA
      div()
        div(v-for="(item, index) in totalDocument.tvaDetails")
          .d-flex.total-row TVA {{ referencielTvaTranslate(item.referencielTvaId) }}
            span.ml-auto.price-total {{ formatCurrency(item.priceTva) }}
        strong.d-flex.total-row.total-with-tax(v-if='totalDocument.tvaDetails.length > 1') Total TVA
          .ml-auto.price-total
            span.totalWithTax {{ formatCurrency(totalDocument.totalTva.toFixed(2)) }}
      
    // Gestion du total TTC
    strong.d-flex.big.bg-primary.text-white.totalInvoice.rounded-bottom(:style="preview ? 'background:'+deliveryForm.institutionColor+' !important' : ''")  TOTAL TTC
      .ml-auto.price-total
        span(class="totalWithTax") {{ formatCurrency(deliveryForm.totalTtc) }}
 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import { VBPopover, BPopover, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import dayjs from "dayjs";
import {
  formatCurrency,
  formatDate,
  formatPercent,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { natureTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {};
  },
  components: {
    "v-select": vSelect,
    popper: Popper,
  },
  props: {
    preview: {
      default: false,
    },
  },
  methods: {
    ...mapActions(["updateDeliveryForm"]),
    formatCurrency,
    formatDate,
    formatPercent,
    formatNumber,
    natureTranslate,
    referencielTvaTranslate,
    // Enregistrement du document
    saveAction() {
      setTimeout(() => {
        this.updateDeliveryForm({
          deliveryForm: this.deliveryForm,
          loading: false,
        });
      }, 200);
    },
    referencielTvaValue(referencielTvaId) {
      return formatNumber(
        this.institutionSettingsActive.referencielTvas.find(
          (item) => item.referencielTva.id == referencielTvaId
        ).referencielTva.value
      );
    },
  },
  computed: {
    ...mapGetters(["getDeliveryFormDetails", "institutionSettingsActive"]),
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
    totalDocument() {
      let totalDocument = {
        subTotalHt: 0,
        totalNetHt: 0,
        tvaDetails: [],
        totalTva: 0,
        totalTtc: 0,
      };

      let documentTvas = [];
      // On génère le tableau TVA
      // On calcule les totaux
      totalDocument.tvaDetails = this.getDeliveryFormDetails
        .filter(
          (elem) =>
            elem.type != 1 &&
            elem.type != 2 &&
            elem.type != 3 &&
            elem.type != 4 &&
            elem.type != 5 &&
            elem.type != 6
        )
        .reduce((acc, obj) => {
          // On crée un object par taux de TVA
          let findIndex = acc.findIndex(
            (el) => el.referencielTvaId === obj.referencielTvaId
          );

          // On calcule les montant selon les quantités et remise par ligne
          let priceBrutHt = 0;
          priceBrutHt =
            Math.round(obj.unitPriceHt * obj.outPutQuantity * 100) / 100;
          let priceNetHt = Math.round(priceBrutHt * 100) / 100;

          if (findIndex < 0) {
            // On instancie un nouveau de TVA
            acc.push({
              priceHt: priceNetHt,
              // priceTva: priceTva,
              // priceTtc: priceTtc,
              priceTva: 0,
              priceTtc: 0,
              referencielTvaId: obj.referencielTvaId,
            });
          } else {
            // On additionne un taux de TVA
            acc[findIndex].priceHt += priceNetHt;
          }

          totalDocument.subTotalHt += Math.round(priceNetHt * 100) / 100;
          totalDocument.totalNetHt += Math.round(priceNetHt * 100) / 100;
          return acc;
        }, []);

      // On calcule le prix de la TVA et le prix TTC
      for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
        const lineTva = totalDocument.tvaDetails[index];
        lineTva.priceTva =
          Math.round(
            ((lineTva.priceHt *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtc = lineTva.priceTva + lineTva.priceHt;
      }

      // On calcule le prix total de la TVA
      totalDocument.totalTva = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTva;
      }, 0);

      // On calcule le prix total TTC
      totalDocument.totalTtc = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTtc;
      }, 0);

      for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
        const lineTva = totalDocument.tvaDetails[index];
        if (
          documentTvas.findIndex(
            (el) =>
              el.referencielTvaId == lineTva.referencielTvaId ||
              (el.referencielTva
                ? el.referencielTva.id == lineTva.referencielTvaId
                : false)
          ) >= 0
        ) {
          let index = documentTvas.findIndex(
            (el) => el.referencielTvaId == lineTva.referencielTvaId
          );
          documentTvas[index].value = lineTva.priceHt;
          documentTvas[index].valueTva = lineTva.priceTva;
        } else {
          documentTvas.push({
            id: 0,
            value: lineTva.priceHt,
            valueTva: lineTva.priceTva,
            referencielTvaId: lineTva.referencielTvaId,
            deliveryFormId: this.deliveryForm.id,
          });
        }
      }

      // On affectre les nouvelles valeurs calculées au document
      this.deliveryForm.valueHt =
        Math.round(totalDocument.totalNetHt * 100) / 100;
      this.deliveryForm.deliveredQuantity = this.getDeliveryFormDetails.reduce(
        (acc, obj) => {
          return parseFloat(acc) + parseFloat(obj.outPutQuantity);
        },
        0
      );
      this.deliveryForm.deliveryFormTvas = documentTvas;

      let _this = this;
      if (totalDocument.tvaDetails && totalDocument.tvaDetails.length > 0) {
        totalDocument.tvaDetails.sort(function (a, b) {
          return (
            _this.referencielTvaValue(a.referencielTvaId) -
            _this.referencielTvaValue(b.referencielTvaId)
          );
        });
      }
      this.$emit("updateTvaDetails", totalDocument.tvaDetails);
      // this.document.tvaDetails = totalDocument.tvaDetails
      this.deliveryForm.totalTtc =
        Number(Math.round(totalDocument.totalNetHt * 100) / 100) +
        Number(Math.round(totalDocument.totalTva * 100) / 100);

      return totalDocument;
    },
  },
};
</script>

<style>
.popper {
  background-color: #ffffff;
  text-align: center;
  padding: 2px;
  border-radius: 3px;
  border: 1px #ebe9f1 solid;
  box-shadow: rgb(220 220 220) 0 0 15px 0;
  color: #6e6b7b;
  min-width: 500px;
}
.totalInvoice {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>
