<template lang="pug">
    table.documentDetails   
      thead
        DeliveryFormPreviewHeader()
      tbody 
        DeliveryFormPreviewDetails(v-for="(line, index) in getDeliveryFormDetails" :key="index" :line="line" :index="index")
</template>
<script>
import DeliveryFormPreviewHeader from "@/components/purchase/delivery-form/preview/table/DeliveryFormPreviewHeader.vue";
import DeliveryFormPreviewDetails from "@/components/purchase/delivery-form/preview/table/DeliveryFormPreviewDetails.vue";
import { EDocumentTypeLine, EInputType } from "@/types/api-orisis/enums/enums";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getDeliveryFormDetails"]),
  },
  components: {
    DeliveryFormPreviewHeader,
    DeliveryFormPreviewDetails,
    draggable,
  },
};
</script>
