<template lang="pug">
  .document-line(ref="documentDetails" @mouseover="edit = true, mouseOn = true" @mouseleave="edit=false, mouseOn=false")
    input(class="w-100" :ref="'fakeInputDeliveryForm'+index" style="width: 0px !important; position: absolute; display:block")
    .product-line.builder(:class="line.type == 4 ? 'align-items-center':''")
      span.feather-drag-document-detail.handle.cursor-move(class='material-icons') drag_indicator
      div.type-line.no-printting.material-icons-outlined.text-danger(v-if="!this.line.description && !this.line.productId && ([7,8,9,10].includes(this.line.type))" v-b-tooltip.hover.v-danger title="Description obligatoire" style="font-size:14px") error
      div.type-line.no-printting.material-icons-outlined.text-danger(v-else-if="!this.line.reference && !this.line.productId && ([7,8,9,10].includes(this.line.type))" v-b-tooltip.hover.v-danger title="Référence obligatoire" style="font-size:14px") error
      div.type-line.no-printting.material-icons-outlined.text-danger(v-else-if="productAlreadyExist(this.line) && !this.line.productId" v-b-tooltip.hover.v-danger title="Ce produit existe dans votre catalogue" style="font-size:14px") error
      div.type-line.no-printting.material-icons-outlined.text-warning.cursor-pointer(v-else-if="(!this.line.productId || this.line.productId == 0) && ([7,8,9,10].includes(this.line.type))" v-b-tooltip.hover.v-warning title="Produit inexistant dans votre base. Cliquez pour l'ajouter" style="font-size:14px" @click="openModal()") info
      div.type-line.no-printting.material-icons-outlined.text-primary(v-else style="font-size:14px") done
      //- div.type-line.no-printting {{orderFormProviderDetailTypeTranslate(line.type)}}
      DeliveryFormDetailCommonLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in Object.keys(deliveryFormHeader)" :key="i"  :dataKey="key" v-if="deliveryFormHeader[key].display && (line.type == 7 || line.type == 8 || line.type == 9  || line.type == 10)" :class="deliveryFormHeader[key].class" :style="deliveryFormHeader[key].display ? deliveryFormHeader[key].style : ''" :line="line" :deliveryFormHeader="deliveryFormHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index") 
      DeliveryFormDetailSectionLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 1 || line.type == 2" :line="line" :class="deliveryFormHeader[key].class" :style="deliveryFormHeader[key].style" :deliveryFormHeader="deliveryFormHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      DeliveryFormDetailSubTotalLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 5" :line="line" :class="deliveryFormHeader[key].class" :style="deliveryFormHeader[key].style" :deliveryFormHeader="deliveryFormHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      DeliveryFormDetailTextLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 6" :class="deliveryFormHeader[key].class" :style="deliveryFormHeader[key].style" :line="line" :deliveryFormHeader="deliveryFormHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      DeliveryFormDetailBreakPageLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 4"  :line="line" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      DeliveryFormDetailBreakLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 3"  :line="line" :deliveryFormHeader="deliveryFormHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      vs-dropdown.more-actions(vs-trigger-click v-if="!document.orderFormId")
        span.handle.cursor-pointer(class='material-icons') expand_more
        vs-dropdown-menu
          vs-dropdown-item(v-if="line.productId" @click="showProductDetails(line)")
            | Afficher la fiche produit
          vs-divider(v-if="line.productId").p-0.m-0
          vs-dropdown-group(vs-collapse='' vs-label='Insérer au dessus' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:7, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:8, unit : 2, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:9, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:10, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Autre
            vs-dropdown-item(@click="newLine({type:6, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Texte
            vs-dropdown-item(@click="newLine({type:4, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Saut de page
            vs-dropdown-item(@click="newLine({type:3, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Saut de ligne
          vs-dropdown-group(vs-collapse='' vs-label='Insérer en dessous' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:7, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:8, unit : 2, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:9, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:10, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
            vs-dropdown-item(@click="newLine({type:6, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" )  Texte
            vs-dropdown-item(@click="newLine({type:4, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" )  Saut de page
            vs-dropdown-item(@click="newLine({type:3, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" )  Saut de ligne
          vs-divider.p-0.m-0
          vs-dropdown-group(vs-collapse='' vs-label='Dupliquer' vs-icon='add')
            vs-dropdown-item(@click="duplicateLineDeliveryForm({ deliveryFormDetail: line, index: index, position: 'before' })")
              | Au dessus
            vs-dropdown-item(@click="duplicateLineDeliveryForm({ deliveryFormDetail: line, index: index, position: 'after' })")
              | En dessous
            vs-dropdown-item(@click="duplicateLineDeliveryForm({ deliveryFormDetail: ine, index: index, position: 'beginning' })")
              | Au début
            vs-dropdown-item(@click="duplicateLineDeliveryForm({ deliveryFormDetail: line, index: index, position: 'end' })")
              | À la fin 
          //- vs-dropdown-item(@click="duplicateLineDeliveryForm({line: line, index: index})")
          //-   | Dupliquer
          vs-dropdown-item.text-danger(@click="deleteLine(line)")
            | Supprimer
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-product-content(ref="sidebarProductContent" @closeSidebar="closeSidebar")
</template>

<script>
import DeliveryFormDetailCommonLine from "@/components/purchase/delivery-form/builder/table/lines/DeliveryFormDetailCommonLine.vue";
import DeliveryFormDetailSectionLine from "@/components/purchase/delivery-form/builder/table/lines/DeliveryFormDetailSectionLine.vue";
import DeliveryFormDetailTextLine from "@/components/purchase/delivery-form/builder/table/lines/DeliveryFormDetailTextLine.vue";
import DeliveryFormDetailBreakPageLine from "@/components/purchase/delivery-form/builder/table/lines/DeliveryFormDetailBreakPageLine.vue";
import DeliveryFormDetailBreakLine from "@/components/purchase/delivery-form/builder/table/lines/DeliveryFormDetailBreakLine.vue";
import DeliveryFormDetailPriceLine from "@/components/purchase/delivery-form/builder/table/lines/DeliveryFormDetailPriceLine.vue";
import DeliveryFormDetailSubTotalLine from "@/components/purchase/delivery-form/builder/table/lines/DeliveryFormDetailSubTotalLine.vue";
import { mapGetters, mapActions } from "vuex";
import { lineChartOptions } from "@/@core/components/statistics-cards/chartOptions";
import { orderFormProviderDetailTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import SidebarProductContent from "@/components/catalog/SidebarProductContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";
import ProductReference from "@/components/catalog/formProduct/ProductReference.vue";

export default {
  data() {
    return {
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      edit: false,
      mouseOn: false,
      editFocus: false,
    };
  },
  computed: {
    deliveryFormHeader: {
      get() {
        return this.$store.getters.getDeliveryFormHeader;
      },
      set(val) {
        this.$store.commit("setupHeaderDeliveryForm", {
          ...deliveryFormHeader,
          val,
        });
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDeliveryFormDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM_DETAILS", value);
      },
    },
    document: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
    columnText() {
      if (this.document.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parendIdHide: {
      type: Number,
    },
    indexToFocus: {
      type: Number,
    },
  },
  watch: {
    indexToFocus(val) {
      if (val == this.index) {
        this.$nextTick(() => {
          this.$refs["fakeInputDeliveryForm" + this.index]?.focus({
            preventScroll: true,
          });
        });
      }
    },
  },
  methods: {
    orderFormProviderDetailTypeTranslate,
    ...mapActions([
      "addLineDeliveryForm",
      "deleteDeliveryFormDetails",
      "duplicateLineDeliveryForm",
    ]),
    productAlreadyExist(val) {
      return this.$store.getters.catalog.some(
        (elem) => elem.reference == val.reference
      );
    },
    openModal() {
      this.$store.commit("SET_PRODUCT_TO_CREATE", this.line);
      this.$store.commit("SET_PRODUCT_PROVIDER_EXISTING", true);
      this.$store.commit("SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT", true);
    },
    changeEditFocus(val) {
      this.editFocus = val;
    },
    showProductDetails(line) {
      if (line.productId) {
        this.isOpen = true;
        this.$refs["sidebarProductContent"].getProductDetails(line.productId);
        this.$refs.sidebar.toggle();
      }
    },
    newLine(line) {
      let _this = this;
      this.addLineDeliveryForm({
        deliveryFormDetail: {
          ...line,
          index: null,
          deliveryFormId: this.document.id,
        },
        review: line.review,
        indexArray: line.indexArray,
      });
      this.$nextTick(() => {
        _this.$refs["fakeInputDeliveryForm" + this.index]?.focus({
          preventScroll: true,
        });
      });
    },
    async deleteLine(line) {
      let _this = this;
      await this.deleteDeliveryFormDetails({
        deliveryFormDetailIds: [line.id],
        reviewIndex: true,
        line: line,
      });
      this.$nextTick(() => {
        _this.$refs["fakeInputDeliveryForm" + this.index]?.focus({
          preventScroll: true,
        });
      });
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
  components: {
    DeliveryFormDetailCommonLine,
    DeliveryFormDetailSectionLine,
    DeliveryFormDetailTextLine,
    DeliveryFormDetailBreakPageLine,
    DeliveryFormDetailBreakLine,
    DeliveryFormDetailPriceLine,
    DeliveryFormDetailSubTotalLine,
    SidebarProductContent,
    "ejs-sidebar": SidebarComponent,
  },
};
</script>
