<template lang="pug">
  b-row(class="my-0")
    b-col(cols="12" class="py-0" class="mb-1")
      b-form-group(label="Référence")
        validation-provider(#default='{ errors }' name='reference')
          b-form-input(v-model="product.reference")
</template>


<script>
import {BFormInput, BRow, BCol, BFormGroup} from 'bootstrap-vue'
import {
  ValidationProvider,
} from "vee-validate";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider
  },
  computed: {
    product: {
      // get(){
      //   if(this.$route.name =="edit-product") {
      //       return this.$store.getters['catalog'].find(p => p.id == this.$route.params.id);
      //   } else {
      //       return this.$store.getters.product;
      //   }
      // },
      // set(value) {
      //   this.$store.dispatch('SET_PRODUCT', {...this.product, reference: value })
      // }
      get() {
        return this.$store.getters.product; 
      },
      set(value) {
        return this.$store.commit("SET_PRODUCT", value);
      }
    },
  }
}
</script>