<template lang="pug">
div
  div.content-span-document-detail(v-if="!deliveryFormHeader[dataKey].editable")
    span(v-model="line[dataKey]") 
    span {{deliveryFormHeader[dataKey].prefix }} 
    span {{deliveryFormHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(deliveryFormHeader[dataKey].numberToFixed) : line[dataKey]}}
    span {{deliveryFormHeader[dataKey].unit}}
  input.w-100(:disabled="line.isDisabled" @keyup.enter="selectProduct()" @focus="emitEditFocus(true)" @blur="blurFunction(false)" v-else :class="[edit && canEdit ? 'edit' : 'no-edit', (line.isDisabled) ? 'disabled' : '']" v-model="line[dataKey]")
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { productMergeByTypeLine } from "@/types/api-orisis/library/DetailDeliveryFormOperations.ts";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/DeliveryFormOperations";

export default {
  data() {
    return {
      calculateSubTotalLineBySection,
    };
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    deliveryFormHeader: {
      type: Object,
      required: false,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "productProviderSortedByProductType",
      "getDeliveryFormDetails",
      "deliveryForm",
    ]),
    deliveryForm: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  methods: {
    productMergeByTypeLine,
    ...mapActions(["updateDeliveryFormDetail", "updateDeliveryForm"]),
    saveAction() {
      this.updateDeliveryForm({
        deliveryForm: this.deliveryForm,
        loading: false,
      });
    },
    async affectValue(val) {
      this.line["description"] = val.description ? val.description : val.label;
      this.line["productId"] = val.id;
      this.line["reference"] = val.reference ? val.reference : null;
      this.line["unitId"] = val.unit ? val.unit.id : this.line["unitId"];
      this.line["unitPriceHt"] = val.netPrice
        ? val.netPrice
        : this.line["unitPriceHt"];
      this.line["total"] = val.netPrice
        ? Math.round(this.line["outPutQuantity"] * val.netPrice * 100) / 100
        : this.line["total"];

      switch (val.productType) {
        case 0:
          this.line["type"] = 7;
          break;
        case 2:
          this.line["type"] = 9;
          break;
        case 4:
          this.line["type"] = 6;
          break;
        case 5:
          this.line["type"] = 8;
          break;
      }
      await this.updateDeliveryFormDetail({ deliveryFormDetail: this.line });
      await this.calculateSubTotalLineBySection(this.line);
    },
    productProvidersListByTypeLine() {
      return this.productMergeByTypeLine(
        this.line,
        this.productProviderSortedByProductType
      );
      // }
    },
    selectProduct() {
      if (
        this.dataKey == "reference" &&
        this.line[this.dataKey] &&
        this.line[this.dataKey].length > 0
      ) {
        let item = null;
        for (let propriete in this.productProvidersListByTypeLine()) {
          if (this.productProvidersListByTypeLine().hasOwnProperty(propriete)) {
            const tableau = this.productProvidersListByTypeLine()[propriete];
            const resultatTrouve = tableau.find(
              (element) => element.reference == this.line[this.dataKey]
            );
            if (resultatTrouve) {
              item = resultatTrouve;
              break;
            }
          }
        }
        if (item) {
          this.affectValue(item);
        }
      }
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      this.updateDeliveryFormDetail({ deliveryFormDetail: this.line });
    },
  },
};
</script>
