<template lang="pug">
tr
    td(v-for="(key, i) in Object.keys(invoiceHeader)" :key="i" v-if="invoiceHeader[key].display" :class="invoiceHeader[key].display ? invoiceHeader[key].class : ''" :style="'background:'+deliveryForm.institutionColor") 
        .header {{invoiceHeader[key].text}}    
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["deliveryForm"]),
    invoiceHeader: {
      get() {
        return this.$store.getters.getDeliveryFormHeader;
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", val);
      },
    },
  },
};
</script>
<!-- <style scoped>
#invoiceBuilder #builderPreview .document-table .document-head .product-line {
  padding: 0.2rem 2.5rem 0.2rem 1rem;
  font-size: 14px !important;
  /* background: #38a1f3; */
  color: #fff;
  display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    
}
</style> -->
