<template lang="pug">
.quote-card(v-if="!preview")
    div(v-if="!document.destockingLocationId")
      b-form-group(label-for='destocking-location')
        validation-provider(#default='{ errors }' name='destocking-location')
          v-select#company.mb-1(ref="selectStorageLocation" :loading='isLoadingStorageLocationsList' :state='errors.length > 0 ? false : null' :value='document.destockingLocationLabel' @input='setValue' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='options.map((elem)=>{return {label:elem.label, value:elem.id}})' :placeholder='placeholder')
            template(v-slot:no-options='')
              template  Aucun r&eacute;sultat trouv&eacute;
            li.border-bottom.p-1.py-50(slot='list-header')
              b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newStorageLocation()' variant='primary' size='sm')
                | + Nouveau point de stockage
                span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                  | open_in_new
          small.text-danger {{ errors[0] }}
    div(v-if="editMode")
      div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
        span.text-primary.font-weight-bold Point de déstockage :
        span.cursor-pointer.text-primary(v-if="editMode" @click='validEditMode') Enregistrer
      div.mb-1
        b-form-group(label-for='destockingLocationLabel' style='flex:1')
          validation-provider(#default='{ errors }' name='destockingLocationLabel')
            b-form-input(placeholder="Nom du point de stockage" v-model="documentEdit.destockingLocationLabel")
      div.mb-1
        b-form-group(label-for='destockingLocationAddress' style='flex:1')
          validation-provider(#default='{ errors }' name='destockingLocationAddress')
            b-form-input(placeholder="Adresse" v-model="documentEdit.destockingLocationAddress") 
      div.mb-1
        b-form-group(label-for='destockingLocationAddressComplement' style='flex:1')
          validation-provider(#default='{ errors }' name='destockingLocationAddressComplement')
            b-form-input(placeholder="Adresse" v-model="documentEdit.destockingLocationAddressComplement") 
      div.mb-1.d-flex
        div.w-25
          b-form-group.pr-1(label-for='destockingLocationZipCode' style='flex:1')
            validation-provider(#default='{ errors }' name='destockingLocationZipCode')
              b-form-input(placeholder="CP"  v-model="documentEdit.destockingLocationZipCode")
        div.w-75
          b-form-group(label-for='destockingLocationCity' style='flex:1')
            validation-provider(#default='{ errors }' name='destockingLocationCity')
              b-form-input(placeholder="Ville" v-model="documentEdit.destockingLocationCity")
      div.mb-1
        b-form-group(label-for='destockingLocationEmail' style='flex:1')
          validation-provider(#default='{ errors }' name='destockingLocationEmail')
            b-form-input(placeholder="Adresse" v-model="documentEdit.destockingLocationEmail") 
      div.mb-1
        b-form-group(label-for='destockingLocationPhone' style='flex:1')
          validation-provider(#default='{ errors }' name='destockingLocationPhone')
            b-form-input(placeholder="Numéro de téléphone" v-model="documentEdit.destockingLocationPhoneNumber") 
    .card-info(v-else-if="document.destockingLocationId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      div(v-if="!editMode")
        .card-tools.pr-0(v-if="showCardTool")
          feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier le point de déstockage'" @click="setEditMode()")
          feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de point de déstockage'")
        p.mb-50.text-primary.font-weight-bold {{ document.destockingLocationLabel  }}
        div(v-if="document.destockingLocationAddress != null")
          span {{ document.destockingLocationAddress }}
        div(v-if="document.destockingLocationAddressComplement != null")
          span {{ document.destockingLocationAddressComplement }}
        div(v-if="document.destockingLocationZipCode != null || document.destockingLocationCity != null")
          span {{ document.destockingLocationZipCode }} {{ document.destockingLocationCity }}
.quote-card(v-else)
    .card-info
      p.mb-50.text-primary.font-weight-bold {{ document.destockingLocationLabel  }}
      div(v-if="document.destockingLocationAddress != null")
        span {{ document.destockingLocationAddress }}
      div(v-if="document.destockingLocationAddressComplement != null")
        span {{ document.destockingLocationAddressComplement }}
      div(v-if="document.destockingLocationZipCode != null || document.destockingLocationCity != null")
        span {{ document.destockingLocationZipCode }} {{ document.destockingLocationCity }}
</template>

<script>
import vSelect from "vue-select";
import { storageLocationTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      editMode: false,
      documentEdit: {},
    };
  },
  created() {
    this.getStorageLocations({});
  },
  computed: {
    ...mapGetters(["isLoadingStorageLocationsList"]),
    document: {
      get() {
        return this.$store.getters.deliveryForm;
      },
      set(value) {
        return this.$store.commit("SET_DELIVERY_FORM", value);
      },
    },
  },
  methods: {
    storageLocationTypeTranslate,
    ...mapActions(["getStorageLocations", "deleteDeliveryFormDetails"]),
    validModal(editCompany) {
      this.document = this.documentEdit;
      this.editMode = false;
    },
    validEditMode() {
      this.validModal(false);
    },
    setEditMode() {
      this.editMode = true;
      // copy document into new variable
      this.documentEdit = JSON.parse(JSON.stringify(this.document));
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        this.$emit(
          "setValue",
          this.options.find((elem) => {
            return elem.id == value.value;
          })
        );
      } else {
        this.$emit("setValue", value);
      }
    },
    resetValue() {
      this.$bvModal
        .msgBoxConfirm(
          "Le changment de point de déstockage implique que votre liste d'articles sera supprimer",
          {
            title: "Souhaitez-vous changer de point de destockage ?",
            size: "sm",
            okVariant: "warning",
            okTitle: "Changer",
            cancelTitle: "Fermer",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((elem) => {
          if (elem) {
            let value = {
              id: null,
              label: null,
              address: null,
              addressComplement: null,
              city: null,
              zipCode: null,
              email: null,
              phoneNumber: null,
            };
            this.value = value;
            this.setValue(value);
            this.document.destockingLocationId = null;
            this.document.destockingLocationLabel = null;
            this.deleteDeliveryFormDetails({
              deliveryFormDetailIds:
                this.$store.getters.getDeliveryFormDetails.map((elem) =>
                  JSON.parse(elem.id)
                ),
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
      // this.$refs['selectCompany'].reset();
    },
    newStorageLocation() {
      this.$router.push({
        name: "new-storage-location",
        params: {
          routeOrigine: "/new-delivery-form/" + this.document.id,
        },
      });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
